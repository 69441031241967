import React from "react";
import languages from "../../languages";
// reactstrap components
import { Row, Col } from "reactstrap";
import * as LottiePlayer from "@lottiefiles/lottie-player";

const Loading = (props) => {
  const contentClassName = props.isFullScreen
    ? "text-center full-screen-content"
    : "text-center";
  return (
    <div className="content">
      <Row className={contentClassName}>
        <Col className="align-self-center ">
          <div className="content py-5 h-100">
            <div className="sk-three-bounce bg-transparent">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Loading;
