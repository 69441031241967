/**
 * Created by morteza on 5/14/17.
 */
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { combineReducers } from 'redux';

import userReducer from './userReducer';
import dataReducer from "./dataReducer";
import ticketCountReducer from "./ticketCountReducer";
import dialogReducer from "./dialogReducer";
import WalletsReducer from "./WalletsReducer";
import walletDetailsReducer from "./walletDetailsReducer";
import CartReducer from "./CartReducer";
import intlReducer from './intl/intlReducer';


const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['dialogReducer']
};

const rootReducer = combineReducers({
    userReducer,
    dataReducer,
    ticketCountReducer,
    dialogReducer,
    WalletsReducer,
    walletDetailsReducer,
    CartReducer,
    intlReducer
});

// const initialState = reducerApp({}, {});

// const rootReducer = (state, action) => {
//   if (action.type === 'LOGOUT_USER') {
//     state = undefined;
//   }
//   return reducerApp(state, action)
// };

export default persistReducer(rootPersistConfig,rootReducer);

