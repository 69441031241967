import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import "assets/css/nucleo-icons.css";
import "assets/css/all.css";
import "assets/css/placeholder.css";
import "assets/scss/black-dashboard-pro-react.scss";
import Loading from "components/Loading/Loading";

import {
  IdleTimerProvider,
  IdleTimerConsumer,
  IIdleTimerContext,
  IdleTimerContext,
  useIdleTimerContext
} from 'react-idle-timer';
import useApi from "services/hooks/useApi";
import authApi from "services/API/auth";
import RequestServer from "RequestServer";
import { setLink } from "Values";
import { logoutUser } from "actions";

const PublicLayout = lazy(() => import("layouts/Public/Public.jsx"));
const RTLLayout = lazy(() => import("layouts/RTL/RTL"));
const AuthLayout = lazy(() => import("layouts/Auth/Auth.jsx"));

const App = (props) => {
  const { store } = props;
  const locale = useSelector((state) => state.intlReducer.locale, shallowEqual);
  const dispath = useDispatch();

  const logout = useApi(authApi.logOut);

  const logOut = (userData) => {
    RequestServer(
      "post",
      "logout",
      (response) => {
        if (response) {
          logout.request();
          dispath(logoutUser())
          setTimeout(() => {
            window.open(setLink("/auth/login", 'en'), "_self");
          }, 100);
        }
      },
      {
        post: {
          id: userData.id
        },
        token: userData.token,
        onAnyFailed: () => {
          // setLoading(false);
        },
      }
    );
  }

  const onIdle = (state) => {
    if (state.userReducer?.id)
      logOut(state.userReducer)
  }

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * 15}
      onIdle={() => onIdle(store.getState())}
    >
      <Suspense fallback={<Loading isFullScreen={true} />}>
        <Switch>
          <Route
            path={
              process.env.PUBLIC_URL + "/:lang(fa|en)?/api/payment/:authority"
            }
            render={() => <PublicLayout />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang(fa|en)?/not-found"}
            render={() => <PublicLayout />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang(fa|en)?/payment-link"}
            render={() => <PublicLayout />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang(fa|en)?/verify"}
            render={() => <PublicLayout />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/:lang(fa|en)?/user/:username"}
            render={() => <PublicLayout />}
            exact
          />
          <Route
            path={
              process.env.PUBLIC_URL +
              "/:lang(fa|en)?/payment/:authority/:signature"
            }
            render={() => <PublicLayout />}
            exact
          />
          <Route
            path={process.env.PUBLIC_URL + `/:lang(fa|en)?/auth`}
            render={() => <AuthLayout />}
          />

          {locale.locale ? (
            <Redirect
              from="/:lang(fa|en)?"
              to={process.env.PUBLIC_URL + `/${locale.locale}/auth/login`}
              exact
            />
          ) : (
            <Redirect from="/:lang(fa|en)?" to="/en/auth/login" />
          )}

          <Route
            path={process.env.PUBLIC_URL + "/:lang(fa|en)?"}
            render={() => <RTLLayout />}
          />
        </Switch>
      </Suspense>
    </IdleTimerProvider>
  );
};

export default App;
