import { WALLETDETAILS } from '../actions';


let initialWallets =[];
const walletDetailsReducer = (state = initialWallets, action) => {

    switch (action.type) {
        case WALLETDETAILS:
            return {...state, ...action.details};
        default:
            return state
    }
};

export default walletDetailsReducer;
