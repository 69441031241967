let languages = {
  fa: {
    sendMoney: "ارسال وجه",
    change: " تغییر دادن",
    holderName: "Account holder name",
    International: "International",
    sameCurrency: "Same Currency",
    reviewDetail: "Review detail of your transfer",
    sortCode: "Sort code",
    outsideEurope: "خارج از اروپا",
    insideEurope: "داخل اروپا",
    swift_BICcode: "SWIFT / BIC code",
    BAN: "BAN / Account number",
    localBankAccount: "Local Bank Account",
    interac: "Interac",
    transit: "Transit And Account Number",
    institutionNo: "Institution Number",
    BSBCode: "BSB Code",
    BIK: "BIK (Bank Code)",
    bankAccountCountry: "Bank Account Country",
    accountType: "نوع حساب",
    accountNo: "شماره حساب",
    ACHRoutingnumber: "ACH Routing number",
    iban: "IBAN",
    theirAccountHolderFirstName: "نام صاحب حساب",
    theirAccountHolderLastName: "نام خانوادگی صاحب حساب",
    NameOfBusiness: "نام ",
    theirEmail: "ایمیل آنها",
    bank: "بانک",
    interacEmail: "Interac Registered Email",

    uploadagain: "بارگزاری مجدد",
    changeAddress: "تغییر آدرس",
    addressPlaceholder: "خیابان,کوچه,پلاک,واحد",
    uploadprofile: " عکس پروفایل ",
    uploadPassport: " عکس سلفی به همراه کارت ملی",
    personalDocs: "مدارک احراز هویت",
    personalinfo: "مشخصات  فردی",
    juridical: "حقیقی",
    corporateBtn: "افزودن اطلاعات کاربر حقوقی",
    payoutReceipt: "رسید برداشت",
    payoutAttention:
      "توجه: وارد کردن آدرس نادرست ممکن است منجر به از دست رفتن منابع مالی شما شود",
    toRialAccount: "به حساب بانکی در ایران",
    toForeignAccount: "ارسال به آدرس بیت کوین دیگر",
    transactionConfirm: "تایید تراکنش",
    transactionReceipt: "رسید تراکنش",
    exchangeReceipt: "رسید اکسچنج",
    gatewayImage: "تصویر درگاه",
    gatewayUsername: "آدرس یکتا",
    calculate: "محاسبه",
    cryptoAddress: "آدرس دریافت",
    QRcryptoSubtitle: "توسط فردی که میخواهد به شما ارسال کند اسکن شود",
    cryptoCurrencyWallet: "کیف پول های رمز ارز",
    tfirstamount: "مبلغ کسر شده از کیف پول",
    withdrawalsource: " مبلغ برداشتی از کیف پول مبدا",
    tamount: "مبلغ انتقالی",
    tCommisssion: "کمیسیون انتقالی",
    PayoutRedirect:
      "جهت برداشت از کیف پول می بایست یک حساب بانکی با ارز لیر در حساب کاربری داشته باشید.",
    planAlert: "آیا مایل به ارتقا سطح حساب کاربری خود هستید ؟",
    yourBalance: " موجودی حساب شما",
    mySelf: "خودم",
    paymentDescription:
      "درخواست انتقال شما با اطلاعات زیر ارسال شده است و برای تأیید عملیات ، لطفاً بر روی تأیید کلیک کنید",
    paymentConfirmation: "تاییدیه پرداخت",
    reciption: "گیرنده",
    inPendingStatus: "در انتظار تایید توسط واحد بازرسی ",
    sendTo: "انتقال به",
    comment: "توضیحات مورد نظر جهت درج در حواله بانکی",
    purpose: "بابت",
    commission5: "به عنوان کمیسیون",
    productName: "نام محصول",
    confirmedMoney: "موجودی قابل برداشت",
    pendingMoney: "موجودی درحال بررسی",
    border: "border-right-0",
    ticket_number: "شماره تیکت",
    convertToCorporate:
      "در صورتی که حساب شما متعلق به یک سازمان (کاربر حقوقی) می باشد اطلاعات سازمان مورد نظر خود را وارد نمایید",
    closed: "بسته شده",
    toMyself: "به خودم",
    toOthers: "به دیگران",
    yes: "بله",
    deleteSure: "آیا از حذف این آیتم اطمینان دارید ؟",
    changeSure: "آیا از تفییر وضعیت این آیتم اطمینان دارید ؟",
    yourRequestRegistered: "درخواست شما با موفقیت ثبت شد.",
    submit: "ثبت",
    fillBillingForm: "لطفا اطلاعات زیر را تکمیل نمایید.",
    refundReason: "علت ریفاند",
    refunded: "بازگشت داده شد",
    yourActivePlan: "طرح فعال شما",
    choose: "انتخاب گزینه",
    uploadProfilePicture: "تصویر پروفایل",
    verifyMobile: "تایید شماره همراه",
    Active: "تایید شده",
    Inactive: " رد شده",
    active: "فعال",
    inactive: "غیر فعال",
    makeInactive: "غیر فعال کردن",
    makeActive: "فعال کردن",
    enter5DigitNumber: "کد ۵ رقمی ارسال شده به شماره همراه خود را وارد نمایید.",
    optional: "اختیاری",
    businessPlus: "کسب و کار ویژه",
    merchantCode: "کد مرچنت",
    trial: "آزمایشی",
    availableAmount: "مبلغ قابل برداشت",
    payoutAmount: "مبلغ برداشت",
    yourResponse: "پاسخ شما",
    supportResponse: "پاسخ پشتیبانی",
    logout: "خروج",
    pleaseChooseYourUsername:
      "لطفا آدرس یکتای درگاه پرداخت شخصی خود را وارد نمایید. ",
    editGateway: "ویرایش درگاه",
    pleaseEnterGateWayInformation: "لطفا اطلاعات وب سایت خود را وارد نمایید.",
    pleaseEnterGateWayTechnicalInformation:
      "لطفا اطلاعات فنی وب سایت خود را وارد نمایید.",
    pleaseEnterGateWayContactInformation:
      "لطفا اطلاعات تماس وب سایت خود را وارد نمایید.",
    individual: "حقیقی",
    toIndividual: "به شخص دیگر",
    corporate: "حقوقی",
    uploadLogo: "بارگذاری لوگو",
    bankAccountReceiptTooltip:
      "این ورودی برای حساب های ریالی اجباری نمی باشد. برای حساب های ارزی می بایست شامل تصویری از رسید بانکی شامل نام صاحب حساب و شماره شبا باشد.",
    pleaseEnterYekpayLinkInformation: "لطفا اطلاعات لینک پرداخت را وارد نمایید",
    pleaseSelectYekpayLinkImage: "لطفا تصویر لینک پرداخت را انتخاب نمایید",
    uploadImage: "بارگزاری تصویر",
    userAgreement:
      "شرایط و قوانین استفاده از سرویس های سایت یک پی را مطالعه نموده و با کلیه موارد آن موافقم.",
    myYekpayLinksDesc:
      "با توجه به اینکه سطح حساب کاربری شما شخصی می باشد، شما می توانید تنها یک لینک پرداخت فعال داشته باشید. جهت فعال کردن لینک پرداخت دیگر می بایست ابتدا لینک پرداخت فعال خود را به حالت تعلیق شده در بیاورید. توجه داشته باشید در صورت حدف لینک پرداخت امکان بازگشت آن دیگر وجود نخواهد داشت. ",
    changeStatus: "تغییر وضعیت",
    delete: "حذف",
    firstNameCanNotChange: "نام وارد شده پس از ثبت نام قابل تغییر نخواهد بود.",
    lastNameCanNotChange:
      "نام خانوادگی وارد شده پس از ثبت نام قابل تغییر نخواهد بود.",
    emailCanNotChange: "ایمیل وارد شده پس از ثبت نام قابل تغییر نخواهد بود.",
    promotionCodeTooltip: "در صورت داشتن کد معرف آن را وارد نمایید.",
    bankAccountImageValidation: "(حداکثر: ۲ مگابایت, jpg,png)",
    Upload: "بارگذاری فایل",

    passportUploadTooltip:
      "لطفا کارت ملی یا گذر نامه خود را در کنار صورت خود قرار داده و تصویر آن را به صورتی که چهره شما واضح و اطلاعات کارت شما خوانا باشد، ارسال نمایید.",
    "passport/identity": "بارگزاری پاسپورت/کارت ملی ",
    profilePicture: "بارگزاری عکس پروفایل ",
    yekpayLinkDescription: "شرح خدمات/کالای فروخته شده",
    yekpayLinkDescriptionPlaceholder:
      "لطفا جزئیات مربوط به فروش خدمات یا محصولات خود را وارد نمایید.",
    yekpayLinkDPayerescriptionPlaceholder:
      "لطفا جزئیات مربوط به خدمات یا محصولاتی که خریداری نموده اید را بیان کنید.",
    name: "نام",
    euroLimitation: "حداقل: ۵ یورو - حداکثر: ۲۵۰۰ یورو",
    tryLimitation: "حداقل: ۱۰ لیر - حداکثر: ۱۵۰۰۰ لیر",
    gbpLimitation: "حداقل: ۵ پوند - حداکثر: ۲۰۰۰ پوند",
    aedLimitation: "حداقل: ۱۰ درهم - حداکثر: ۱۰۰۰۰ درهم",
    individualGatewayDocText:
      "جهت تایید درگاه لطفا مدارک را بارگذاری نمایید: (تصویر کارت ملی - تصویر شناسنامه - اجاره نامه یا سند مالکیت)",
    CorporateGatewayDocText:
      "جهت تایید درگاه لطفا مدارک را بارگذاری نمایید: (آگهی روزنامه رسمی - آگهی آخرین تغییرات - تصویر کارت ملی صاحبان امضا - تصویر شناسنامه - اجاره نامه یا سند مالکیت)",
    GatewayDocImageValidation: "(حداکثر: ۲ مگابایت, jpg,png,pdf)",
    official_newspaper: "آگهی روزنامه رسمی",
    identity_card: "کارت ملی",
    addwebsitegatewaydocument: "مدارک درگاه پرداخت",
    upload: "بارگذاری",
    melli_card: "کارت ملی",
    shenasname: "شناسنامه",
    passport: "Passport",
    ownership_documnet: "اجاره نامه یا سند مالکیت",
    bussiness_license: "جواز کسب",
    police_license: "مجوز نیروی انتظامی",
    institute_license: "مجوز موسسه یا نهاد",
    activity_license: "مجوز فعالیت",
    ownership_melli_card: "کارت ملی صاحبان امضا",
    last_changes_certification: "آگهی آخرین تغییرات",
    websiteGatewayDocForApproval: "مدارک درگاه پرداخت وب سایت",
    upload_guidance: "راهنمای بارگذاری",
    selectDocType: "انتخاب نوع مدرک",
    gatewayDoc1:
      "جهت تایید درگاه پرداخت وبسایت می بایست مدارک زیر بارگذاری و توسط واحد بازرسی تایید گردد:",
    gatewayDoc2: "مدارک مورد نیاز اشخاص حقیقی: ",
    gatewayDoc3:
      "تصویر کارت ملی - تصویر شناسنامه - اجاره و یا سند مالکیت همنام با دارنده حساب کاربری",
    gatewayDoc4: "مدارک مورد نیاز اشخاص حقوقی: ",
    gatewayDoc5:
      "آگهی روزنامه رسمی - آگهی آخرین تغییرات شرکت - تصویر کارت ملی صاحبان امضا - تصویر شناسنامه صاحبان امضا - اجاره نامه و یا سند مالکیت به نام شرکت",
    gatewayDoc6: "در نظر داشته باشید:",
    gatewayDoc7: "تمامی مدارک می بایستی با کیفیت بالا و رنگی اسکن شده باشند.",
    gatewayDoc8:
      "حجم هر فایل می بایست کمتر از 2 مگابایت و با یکی از فرمت های jpeg,png,pdf باشد.",
    gatewayDoc9:
      "موسسه های خیریه و مردم نهاد، به غیر از موارد اعلامی می بایست مجوز های مربوط از مراجع ذی ربط را نیز بارگذاری نمایند.",
    gatewayDoc10:
      "در صورتی که اشخاص حقیقی اجاره نامه و یا سند مالکیت به نام خود نداشته باشند، می بایست تفاهم نامه با مالک خود را بارگذاری نمایند.",
    yekpay__form_title:
      "لطفا فرم زیرا را با دقت تکمیل نمایید. در غیر این صورت تراکنش شما ممکن است لغو گردد.",
    affiliate: "مشارکت در فروش",
    affiliate_description:
      "در طرح مشارکت در فروش یک پی، کاربرانی که با کد معرف شما ثبت نام کنند، پس از انجام تراکنش (موفق و تایید شده) به مبلغ حداقل ۱۰۰ یورو توسط درگاه های ارزی یک پی، کیف پول شما معادل ۵ یورو شارژ خواهد شد. ",
    your_promotion_code: "کد معرف شما:",
    registered_users_with_your_code: "لیست کاربرانی که توسط شما معرفی شده اند",
    done_transaction: "انجام تراکنش ارزی",
    transaction_map: "نقشه تراکنش ها",
    available_bussiness_pro:
      "این قابلیت در سطح کاربری کسب و کار ویژه فعال می باشد.",
    this_month_trannsactions: "موقعیت مکانی تراکنش های این  ماه شما",
    bussiness_plus_available:
      "این قابلیت تنها در سطح حساب کسب و کار ویژه فعال می باشد.",
    cannot_changed: "اطلاعات وارد شده پس از ثبت نام قابل تغییر نخواهد بود.",
    agree_terms:
      "ثبت نام به منزله آگاهی و قبول تمامی قوانین و مقررات یک پی می باشد.",
    enter_credit_card_info: "اطلاعات کارت خود را وارد نمایید",
    card_number: "شماره کارت",
    name_on_card: "نام",
    expire_date: "تاریخ انقضا (سال/ماه)",
    cvc: "رمز کارت",
    add_wallet_for_personal_gateway:
      "جهت ساخت درگاه پرداخت شخصی نیاز به ایجاد کیف پول می باشد.",
    active_user_for_personal_gateway:
      "لطفا پیش از ایجاد درگاه پرداخت شخصی اقدام به تکمیل پروفایل کاربری خود نمایید.",
    active_user_for_yekpay_link:
      "لطفا پیش از ایجاد یک پی لینک اقدام به تکمیل پروفایل کاربری خود نمایید.",
    active_profile: "پروفایل کاربری",
    add_wallet: "اضافه کردن کیف پول",
    createWallet: " اضافه کردن کیف پول",

    add_wallet_for_payment_gateway:
      "جهت ساخت درگاه پرداخت وبسایت نیاز به ایجاد کیف پول می باشد.",
    add_wallet_for_yekpay_link:
      "جهت ساخت یکپی لینک نیاز به ایجاد کیف پول می باشد.",
    unsuccessful_transactions: "تراکنش های ناموفق",
    error_code: "کد خطا",
    melli_code_passport_number: "کد ملی یا شماره پاسپورت",
    at_now_just_try:
      "در حال حاضر تنها امکان شارژ کیف پول لیر و یورو وجود دارد.",
    at_now_just_try_payment_link:
      "در حال حاضر پرداخت با ارز لیر و یورو میسر می باشد. ",
    payee: "پرداخت کننده",
    gatewayModal:
      "با توجه به تعطیلات نوروز در ایران و شرایط به وجود آمده توسط بیماری کرونا؛ تمام تراکنش هایی که از تاریخ شنبه ۲۴ اسفند ۹۸ به بعد صورت گیرد، از تاریخ ۱۷ فروردین ۹۹ قابل برداشت به ریال از یک پی خواهد بود. در طول این زمان مشتریان می توانند از خدمات پرداخت یک پی از جمله درگاه پرداخت وبسایت، درگاه پرداخت شخصی، یک پی لینک، شارژ کیف پول و غیره بدون مشکل استفاده نمایند.",
    gatewayModal1: "کاربر گرامی یک پی؛",
    notice: "توجه",
    pay_try: "سیسیسی",
    WaitingResponse: "منتظر پاسخ",
    answered: "پاسخ داده شد.",
    announcementTitle: "توافقنامه کاربری یک پی بروزرسانی گردید.",
    announcementText:
      "به اطلاع می رساند مطابق با قوانین جاری بین الملل در حوزه  پرداخت، از تاریخ 18 فروردین 1399 مبالغ تراکنش هایی که از هر یک از خدمات یک پی صورت می پذیرند، پس از مدت زمان ذکر شده قابل برداشت خواهند بود" +
      "درگاه پرداخت وبسایت (25 روز) " +
      "درگاه پرداخت شخصی (7 روز) " +
      "یک پی لینک (7 روز) " +
      "شارژ کیف پول (1 روز) " +
      "توجه داشته باشید تراکنش هایی که با ارز ریال از طریق درگاه های پرداخت وبسایت و شارژ کیف پول انجام می گیرند، تا مدت زمان 3 ساعت در انتظار تایید مانده و سپس به کیف پول شما آزاد می گردد" +
      "همچنین تراکنش هایی که قبل از تاریخ اعلام شده صورت گرفته باشند، مطابق شرایط سابق قابل برداشت خواهند بود",
    announcement: "اطلاعیه ها",
    clickHere: "جهت اطلاعات بیشتر کلیک نمایید.",
    userAgreement2: "مطالعه بیشتر",
    announcementLink: "https://yekpay.com/fa/user-agreement",
    announcementBase: "مهم: ",
    announcementOk: "تایید",
    copied: "کپی شد",
    paymentServiceProvider: "ارائه دهنده خدمات پرداخت",
    paymentTerms: "شرایط پرداخت",
    exchangeBlockedText:
      "به اطلاع می رساند، در حال حاضر به دلیل نوسانات مقطعی نرخ ازر، سرویس تبادل ارز همه روزه به جز ایام تعطیل از ساعت 12:30 تا 16:00 در دسترس خواهد بود.",
    IRR: "ریال",
    yekpayUserEmail: "ایمیل کاربر یک پی",
    fillInEnglish: "به زبان انگلیسی وارد گردد",
    readMore: "مطالعه قوانین",
    websiteSubmit: "ثبت وب سایت",
    websiteGatewayChecklist1:
      "جهت اخذ درگاه پرداخت، وب سایت شما می بایست شرایط زیر را دارا باشد:",
    websiteGatewayChecklist2: "محتوای وب سایت به صورت کامل بارگذاری شده باشد.",
    websiteGatewayChecklist3:
      "محتوای وب سایت با قوانین یک پی مطابقت داشته باشد.",
    websiteGatewayChecklist4:
      "فرآیند قیمت گذاری و مسیر پرداخت مشتری به صورت کامل ایجاد شده باشد. (این فرایند در صفحه 7و8 فایل مستندات پیاده سازی، توضیح داده شده است.)",
    agree: "ثبت وب سایت",
    fiatWallets: "کیف پول های فیات",
    transactionError: "خطای تراکنش",
    informationChanged: "اطلاعات شما بروز رسانی گردید.",
    PaymentGatewayDocument: "مستندات درگاه پرداخت وب سایت",
    search: "جستجو",
    filter: "فیلتر",
    export: "خروجی",
    fromDate: "از تاریخ",
    toDate: "تا تاریخ",
    successfulRegister: "ثبت نام شما با موفقیت انجام شد.",
    errorCode: "کد خطا",
    exrate: "نرخ ارز",
    notifications: "اعلانات",
    accountVerification: "تایید حساب کاربری",
    cryptoCurrency: "رمز ارز",
    sourceWallet: "آدرس کیف پول مبدا",
    cryptoTopupTitle: "شارژ کیف پول - رمز ارزها",
    profileGeneralDescription:
      "جهت تایید حساب کاربری می بایست اطلاعات زیر را با دقت تکمیل نمایید. پس از تکمیل،  اطلاعات شما توسط واحد پشتیبانی طی 24 ساعت بررسی و نتیجه اعلام خواهد گردید.",
    accountPlanDescription:
      "جهت ارتقا حساب کاربری می بایست کیف پول یورو خود را شارژ نمایید و یا ابتدا کیف پول ریال خود را شارژ و سپس به یورو تبدیل نمایید.",
    seePricingPage: "مشاهده امکانات سطح های حساب های کاربری",
    pluginDescription:
      "جهت خرید و دانلود پلاگین ها می بایست کیف پول یورو خود را شارژ نمایید و یا ابتدا کیف پول ریال خود را شارژ و سپس به یورو تبدیل نمایید.",
    yourEmailVerified: "پست الکترونیکی شما تایید گردید.",
    activateProfile: "حساب کاربری خود را تایید نمایید",
    paymentLink:
      "به استحضار می رساند، به دلیل به روزرسانی و تغییرات فنی تکمیلی جهت توسعه زیرساخت امنیتی، سرویس های یک پی لینک و درگاه پرداخت شخصی تا اطلاع ثانوی در دسترس نخواهند بود. به محض پایان به روزرسانی، اطلاع رسانی از طریق ایمیل صورت خواهد گرفت.",
    thanks: "با تشکر از صبر و شکیبایی شما همراه همیشگی یک پی",
    exchangeWarning:
      "با توجه به اعمال محدودیت های بانکی امکان برداشت ریالی بلافاصله پس از تبدیل ارز به ریال وجود ندارد، لطفا با در نظر داشتن موارد فوق تبدیل ارز خود را انجام دهید.",
    dearUser: "کاربر گرامی یک پی",
    payoutWarning:
      "با توجه به اعمال محدودیت های بانکی در انتقال وجه ریالی، در حال حاضر امکان برداشت وجه از کیف پول ریال با اختلال همراه خواهد بود. لطفا درخواست خود را از طریق سامانه تیکت ارسال نمایید. اطلاعات حساب شما در لیست اولویت پرداخت قرار گرفته و واریز وجه طی روزهای آتی صورت خواهد پذیرفت.",
    pluginShopDescription:
      "جهت دانلود پلاگین می بایست  پلاگین های درخواستی خود را به سبد خرید اضافه نمایید و هزینه آن را پرداخت نمایید. پس از پرداخت موفق لینک دانلود پلاگین های درخواستی به آدرس ایمیل شما ارسال خواهد شد.",
    addToCart: "افزودن به سبد خرید",
    cart: "سبد خرید",
    addToCartTitle: "پلاگین مورد نظر به سبد خرید شما اضافه شد.",
    irr: "ریال",
    removeCart: "حذف از سبد خرید",
    pluginCartDescription:
      "لطفا قبل از پرداخت سبد خرید خود را بررسی و قوانین و مقررات را مطالعه نمایید.",
    productLogo: "تصویر محصول",
    productName: "نام محصول",
    productAmount: "قیمت محصول",
    totalAmount: "قیمت کل",
    rules: "قوانین و مقررات یک پی",
    payWith: "پرداخت آنلاین",
    receipt: "رسید پرداخت",
    receiptDesc:
      "تراکنش شما با شماره پیگیری 1234 با موفقیت انجام شد. لینک دانلود به آدرس ایمیل شما ارسال گردید. صورتحساب شما به شرح زیر می باشد:",
    removeCartTitle: "پلاگین مورد نظر از سبد خرید شما حذف گردید.",

    errorlogin:
      "شما در مرورگر دیگری وارد حساب کاربری خود شده اید و یا زمان نشست شما به پایان رسیده است. لطفا مجددا وارد حساب کاربری خود شوید.",

    successful: "موفق",
    unSuccessful: "ناموفق",
    ok: "باشه",
    fullName: "نام و نام خانوادگی",
    description: "توضیحات",
    discount: "کد تخفیف",
    email: "ایمیل",
    password: "کلمه عبور",
    login: "ورود",
    doNotHaveAccount: "حساب کاربری ندارید؟",
    createAccount: "ساخت حساب کاربری",
    forgotPasswordQuestion: "کلمه عبور خود را فراموش کرده اید؟",
    loginDescription:
      "یک پی اولین و تنها پلتفرم پرداخت آنلاین در حوزه ریالی و ارزی است که با هدف تسهیل تراکنش های مالی بین المللی برای افراد و کسب و کارها در ایران و خارج از کشور سرویس های سریع، مطمئن و مقرون به صرفه‌ای را در بستر امن بانکی ارائه می دهد.",
    TimerDescription: "ثانیه تا درخواست مجدد کد",
    TimerSubmit: "ارسال مجدد",
    forgotPassword: "فراموشی کلمه عبور",
    forgotPasswordEmailSent: "کد تایید به آدرس پست الکترونیک شما ارسال گردید.",
    confirmationCode: "کد تایید",
    confirmationCodeAccepted:
      "کد شما تایید گردید. می توانید کلمه عبور جدید خود را تعریف نمایید.",
    passwordChangedSuccessfully: "کلمه عبور شما با موفقیت تغییر یافت.",
    yourSend: "مبلغ برداشتی از کیف پول",
    yourSendCrypto: "مقدار برداشتی از کیف پول",
    reciptionGet: "مبلغ دریافتی در حساب مقصد",
    getAmount: " مبلغ دریافتی در کیف پول مقصد",
    register: "ثبت نام",
    firstName: "نام",
    lastName: "نام خانوادگی",
    promotionCode: "کد معرف",
    haveAccount: "حساب کاربری دارید؟",
    mobile: "تلفن همراه",
    phone: "تلفن",
    five: "(۵٪)",
    verifyMobile: "دریافت کد",
    mobileActivation: "فعال سازی تلفن همراه",
    mobileVerificationCodeSend: "کد تایید تلفن همراه برای شما پیامک گردید",
    mobileVerificationDescription:
      "لطفا جهت دریافت کد تایید شماره همراه خود را وارد کنید",
    sendMobileVerificationAgain: "در صورت عدم دریافت پیامک اینجا کلیک کنید",
    emailActivation: "فعال سازی پست الکترونیک",
    emailVerificationCodeSent: "کد تایید پست الکترونیک برای شما ایمیل گردید.",
    emailVerificationDescription:
      "یک کد ۵ رقمی به آدرس پست الکترونیکی شما ارسال گردیده است. لطفا کد ارسالی را وارد نمایید.",
    sendEmailVerificationAgain: "درصورت عدم دریافت ایمیل اینجا کلیک نمایید.",
    doneSuccessfully: "با موفقیت انجام گردید.",

    topup: "شارژ کیف پول",
    chargeWalletTitle: "شارژ کیف پول",
    chargeWalletDescription:
      "شما می توانید کیف پول های ریالی و ارزی خود را با کارت شتابی یا کارت های بین المللی به صورت آنی و یا به وسیله حواله بانکی (بسته به زمان واریز) شارژ نمایید.",
    selectChargeWalletMethod:
      "شما می توانید با استفاده از کارت های بانکی بین المللی و یا حواله بانکی بین المللی کیف پول خود را شارژ نمایید.",
    shetabCards: "کارت‌های شتابی",
    wire: "حواله",
    creditCards: "کارت‌های ارزی",
    irrTopupDescription:
      "  برای شارژ کیف پول ریالی خود می توانید با استفاده از کارت شتابی متعلق به خود، کیف پول خود را به میزان حداقل 5000 هزار تومان و حداکثر 50 میلون تومان شارژ نمایید. پس از انجام تراکنش موفق، مبلغ مورد نظر طی 3 ساعت بررسی و به کیف پول شما افزوده خواهد شد",
    payoutWireDescript:
      "برای برداشت از کیف پول و انتقال به حساب بانکی در خارج از کشور می بایست مبلغ برداشت، اطلاعات حساب مقصد، علت انتقال و اطلاعات تکمیلی در فرم حواله بانکی را تکمیل نمایید. پس از ثبت اطلاعات حساب شما و مقصد بررسی می گردد و مبلغ درخواستی طی 3 الی 10 روز کاری منتقل می گردد. کارمزد برداشت 5 درصد مبلغ انتقالی و حداقل کارمزد 50 یورو می باشد.",

    confirmations: "تاییدیه ها",
    created_at: "زمان ساخت",
    fee: "کارمزد",
    updated_at: "زمان به روز رسانی",
    irrTopupTitle: "شارژ کیف پول - کارت های شتابی",
    wireTopupTitle: "شارژ کیف پول - حواله بانکی",
    wireTopupDescription:
      "شما می توانید کیف پول خود را به وسیله حواله بانکی شارژ نمایید. بدین منظور می بایست فرم زیر را بر اساس اطلاعات واریز کننده پر نمایید. پس از تایید اطلاعات طی 24 ساعت ایمیلی حاوی اطلاعات حساب بانکی به آدرس ایمیل شما ارسال خواهد شد و پس از واریز وجه در حساب اعلام شده کیف پول شما به همان مقدار شارژ خواهد شد.",
    creditTopupTitle: "شارژ کیف پول - کارت های ارزی",
    creditTopupDescriptionEUR:
      "برای شارژ توسط کارت های بین المللی با فعال کردن گزینه ۳-Secure (رمز دوم پویا) و نیز فعالسازی امکان پرداخت در منطقه خاورمیانه بر روی کارت خود، امکان شارژ کیف پول را خواهید داشت. حداقل میزان شارژ کیف پول 5 یورو و حداکثر ۲۵۰۰ یورو در هر تراکنش می باشد.پس از انجام تراکنش موفق، مبلغ مورد نظر طی ۲۴ ساعت بررسی و به کیف پول شما افزوده خواهد شد.",
    creditTopupDescriptionGBP:
      "برای شارژ توسط کارت های بین المللی با فعال کردن گزینه ۳-Secure (رمز دوم پویا) و نیز فعالسازی امکان پرداخت در منطقه خاورمیانه بر روی کارت خود، امکان شارژ کیف پول را خواهید داشت. حداقل میزان شارژ کیف پول 5 پوند و حداکثر ۲۰۰۰ پوند در هر تراکنش می باشد.پس از انجام تراکنش موفق، مبلغ مورد نظر طی ۲۴ ساعت بررسی و به کیف پول شما افزوده خواهد شد.",

    newWallet: "کیف پول جدید",
    creditTopupDescriptionTRY:
      "برای شارژ توسط کارت های بین المللی با فعال کردن گزینه ۳-Secure (رمز دوم پویا) و نیز فعالسازی امکان پرداخت در منطقه خاورمیانه بر روی کارت خود، امکان شارژ کیف پول را خواهید داشت. حداقل میزان شارژ کیف پول ۱۰ لیر و حداکثر ۱۰۰۰۰ لیر در هر تراکنش می باشد. پس از انجام تراکنش موفق، مبلغ مورد نظر طی ۲۴ ساعت بررسی و به کیف پول شما افزوده خواهد شد. تراکنش ها مشمول ۱۸ درصد مالیات بر ارزش افزوده خواهد گردید.",
    LirpayoutWireDescript:
      "برای برداشت از کیف پول و انتقال به حساب های بانکی کشور ترکیه می بایست مبلغ برداشت، اطلاعات حساب مقصد، علت انتقال و اطلاعات تکمیلی در فرم حواله بانکی را تکمیل نمایید. پس از ثبت اطلاعات حساب شما و مقصد بررسی می گردد و مبلغ درخواستی طی حداکثر 3 ساعت کاری (9 صبح الی 4 بعد از ظهر به وقت ترکیه) منتقل می گردد. کارمزد برداشت 5 درصد مبلغ انتقالی و حداقل کارمزد 30 لیر می باشد. ",
    walletDeposit: "واریز",
    walletPayout: "برداشت",
    walletCharge: "شارژ کیف پول",
    walletExchange: "اکسچنج",

    transactionReceipt: "رسید تراکنش",
    transactionTime: "زمان تراکنش",
    transactionStatus: "وضعیت تراکنش",

    googleSignIn: "ورود با گوگل",
    googleSignUp: "ثبت نام با گوگل",
    donthaveanacco: "حساب کاربری ندارید؟",
    haveanacco: "حساب کاربری دارید؟",
    createacc: "ساخت حساب کاربری",
    forgotpassqestion: "کلمه عبور خود را فراموش کرده اید ؟",
    Pending: "در انتظار تایید",
    Declined: "رد شده",

    pending: "در انتظار تایید",
    suspend: "تعلیق شده",
    declined: "رد شده",
    canceled: "کنسل شده",

    payout: "برداشت از کیف پول",
    payoutDescription:
      "شما میتوانید موجودی کیف پول خود را به حساب بانکی خود در داخل کشور توسط انتقال پایا و یا از طریق ارسال حواله به حساب های خارج از کشور واریز نمایید. کارمزد خدمات یک پی به شرح زیر است",
    selectPayoutMethod: "روش خود جهت برداشت را انتخاب نمایید",
    payoutIRRTitle: "برداشت از کیف پول - برداشت ریالی",
    payoutIRRDescription:
      "شما می توانید موجودی کیف پول ریالی خود را به حساب بانکی که تعریف کرده اید ارسال نمایید. این امکان در روزهای کاری بانک های ایران از شنبه تا چهارشنبه و از ساعت 8 صبح الی 3:30 بعد از ظهر میسر می باشد. لازم به ذکر است مجموع مبلغ درخواستی و کمیسیون نباید از موجودی کیف پول ریالی شما بیشتر باشد.",
    payoutTransferTitle: "برداشت از کیف پول - به حساب یک پی دیگر",
    payoutTransferDescription:
      "شما می توانید موجودی هر یک از کیف پول های خود را به کیف پول های کاربران دیگر یک پی به صورت آنی و بدون کمیسیون انتقال دهید. لازم به ذکر است جهت انتقال می بایست طرف مقابل نیز کیف پول ارز مورد نظر جهت انتقال را دارا باشد.",
    payoutWireTitle: "برداشت از کیف پول - انتقال حواله",
    payoutWireDescription:
      "شما می توانید موجودی کیف پول خود را به حساب های بانکی که تعریف کرده اید به صورت حواله بانکی انتقال دهید. بدین منظور پس از تکمیل فرم زیر، مبلغ مورد نظر شما به وسیله حواله بانکی به حساب شما واریز خوهد شد.",
    IRRPayout: "برداشت ریالی",
    wireTransfer: "حواله بانکی",

    bankAccount: "حساب بانکی",
    holderName: "نام صاحب حساب",
    holderLastName: "نام خانوادگی صاحب حساب",
    companyName: "نام شرکت",
    companyInfo: " اطلاعات شرکت",

    download: "دانلود",
    buyPlugin: "خرید پلاگین",

    exchangeTitle: "اکسچنج",
    exchangeDescription:
      "جهت اکسچنج لطفا کیف پول مبدا، مقصد و مبلغ درخواستی انتخاب نمایید. توجه داشته باشید هر اکسچنج شامل 500 ریال کارمزد در اکسچنج با ارز مبدا ریال و 30 سنت در اکسچنج با ارز مبدا غیر از ریال می گردد. در حال حاضر به دلیل نوسانات مقطعی نرخ ازر، سرویس اکسچنج همه روزه به جز ایام تعطیل از ساعت 12:30 تا 16:00 در دسترس خواهد بود",

    fromWallet: "کیف پول مبدا",
    toWallet: "کیف پول مقصد",

    yekpayLinkName: "نام یک پی لینک",
    operations: "عملیات",

    yekpay: "یک پی",
    wallet: "کیف پول",
    wrongPassword: "نام کاربری و کلمه عبور همخوانی ندارند.",
    reason: "دلیل درخواست",
    refundTitle: "درخواست بازگشت پول",
    refund: "بازگشت پول",
    verify: "تایید",
    email_has_taken: "یک حساب کاربری با پست الکترونیکی وارد شده موجود می باشد.",
    yekpaydashboard: "داشبورد یک پی",
    tickets: "تیکت ها",
    messages: "پیام ها",
    exit: "خروج",
    dollar: "دلار",
    euro: "یورو",
    copy: "کپی",
    copyright: "کلیه حقوق متعلق به وبسایت یک پی می باشد.",
    chooseimage: "انتخاب تصویر",
    edit: "ویرایش",
    remove: "حذف",
    loading: "درحال بارگذاری",
    addbankacc: "افزودن حساب بانکی",
    shabanum: "شماره شبا",
    swiftnum: "کد سوییفت",
    swiftplaceh: "کد سوئیفت برای حساب بانکی ایرانی ضروری نیست",
    accholdername: "نام صاحب حساب",
    nameandfamilyname: "نام و نام‌خانوادگی",
    bankandbranch: "نام بانک و شعبه",
    bankandbranchplaceh: "ملت - اجودانیه",
    receiptstatusupload:
      "صورت وضعیت حساب یا رسید بانکی که نام و نام خانوادگی و شماره شبا/IBAN شما در آن درج شده را را بارگزاری نمایید",
    uploadreceipt: "بارگزاری رسید",
    add: "افزودن",
    mybankaccounts: "حساب های بانکی من",
    addbankaccounts: "افزودن حساب بانکی",
    start: "شروع",
    end: "پایان",
    renew: "تازه سازی",
    bankaccounttitle: "حساب بانکی",
    bankaccountdesc:
      "جهت واریز موجودی کیف پول خود به حساب های ریالی و یا ارزی، به تعریف حساب بانکی به نام خود نیاز دارید. جهت ثبت حساب بانکی، فرم مربوطه را تکمیل نمایید سپس کارشناسان پشتیبانی تا 24 ساعت نسبت به تایید یا رد آن اقدام خواهند کرد.",
    bankaccountlist: "لیست حساب های بانکی",
    status: "وضعیت",
    createddate: "تاریخ ایجاد",
    documents: "مستندات",
    codesandissues: "نمونه کد و خطاها",
    developerstitle: "راهنمای برنامه نویسان",
    developersdesc:
      "چنانچه از CMS خاصی استفاده می کنید، می توانید پلاگین مورد نیاز خود را از بخش پلاگین ها خریداری و دانلود نمایید. همچنین می توانید از داکیومنت های موجود در بخش مستندات برای راه اندازی درگاه بر روی وب سایت خود استفاده نمایید.",
    plugins: "پلاگین ها",
    codes: "مستندات",
    pluginshop: "فروشگاه یک پی",
    woocommerce: "Woocommerce",
    version: "نسخه",
    language: "زبان",
    currency: "کیف پول",
    selectwallet: "انتخاب کیف پول",
    confirm: "تایید",
    billreceipt: "رسید تراکنش",
    continue: "ادامه",
    back: "قبل",
    complete: "تکمیل",
    payoutfrom: "برداشت از",
    amount: "مبلغ",
    value: "مقدار",
    converto: "تبدیل به",
    select: "انتخاب گزینه",
    confirmexchange: "تایید اکسچنج",
    date: "تاریخ",
    save: "ذخیره",
    print: "پرینت",
    share: "اشتراک گذاری",
    mastercardtitle: "مستر کارت",
    mastercarddesc:
      "برای تبادل ارز لطفا کیف پول مبدا و مقصد خود را انتخاب بفرمایید توجه داشته باشید هرگونه تبادل ارز شامل مالیات خواهد شد. برای تبادل ارز لطفا کیف پول مبدا و مقصد خود را انتخاب بفرمایید توجه داشته باشید هرگونه تبادل ارز شامل مالیات خواهد شد.",
    mymastercards: "کارت های من",
    addmastercard: "اضافه کردن کارت",
    balance: "موجودی",
    hour: "ساعت",
    platform: "پلتفورم",
    browser: "مرورگر",
    mastercardname: "نام کارت",
    mastercardwiztittle:
      "قبل از پرداخت میبایست فرم زیر را بر اساس اطلاعات پرداخت کننده تکمیل کنید",
    birthdate: "تاریخ تولد",
    familyname: "نام خانوادگی",
    country: "کشور",
    city: "شهر",
    address: "آدرس",
    postalcode: "کد پستی",
    nationality: "ملیت",
    nationalcode: "کد ملی",
    passnum: "شماره پاسپورت",
    telephonenum: "شماره تماس",
    uploadpass: " پاسپورت",
    uploaddoc: "بارگزاری سند",
    mastercardreceipt: "رسید صدور کارت بین المللی",
    paywith: "پرداخت با کیف",

    locationinfo: "موقعیت فعلی",
    contactinfo: "اطلاعات تماس",
    paycontactinfo: "اطلاعات دریافت کننده",
    docs: "مستندات",
    receipt: "رسید",
    issueDebitCard: "صدور کارت بین المللی",
    mywallettitle: "ساخت کیف پول",
    mywalletdesc:
      "جهت انجام تراکنش نیاز به تعریف کیف پول می باشد. شما می توانید بسته به نوع اکانت خود کیف پول های مورد نظر خود را اضافه نمایید. توجه داشته باشید امکان تغییر و یا حذف کیف پول هایی که بر روی آن تراکنشی (موفق و یا نا موفق) انجام گردیده وجود ندارد. ",
    yoanwallet: "کیف پول یو آن",
    rialwallet: "کیف پول ریال",
    eurowallet: "کیف پول یورو",
    dollarwallet: "کیف پول دلار",
    chooseYourWallet: "کیف پول خود را انتخاب کنید",
    lirwallet: "کیف پول لیر",
    rial: "ریال",
    method: "روش برداشت وجه",
    toAnotherYekpay: "به سایر کاربران یک پی",
    rialPayout: "پرداخت ریالی",
    toswift: "انتقال حواله",
    payoutenteramount: "مبلغ مورد نظر برای برداشت وجه را وارد نمایید",
    payoutselecbankacc: "حساب بانکی خود را انتخاب نمایید",
    personalgatewaytitle: "درگاه پرداخت شخصی",
    personalgatewaydesc:
      "با درگاه شخصی یک پی می توانید تنها با ارایه QR و یا آدرس یکتا اختصاصی خود در یک پی آن را به مشتریان خود ارسال نموده و بصورت آنی و آنلاین وجه ارزی را دریافت کرده و به حساب بانکی خود انتقال دهید.",
    accountsection: "ناحیه کاربری",
    addavatar: "افزودن تصویر",
    accountview: "نمایش کاربری",
    selectusername: "نام کاربری خود را انتخاب کنید",
    username: "نام کاربری",
    selectavatar: "تصویر درگاه پرداخت شخصی خود را بارگذاری نمایید.",
    uploadavatar: "بارگزاری تصویر",
    profile: "پروفایل کاربری",
    general: "عمومی",
    billinfo: "اطلاعات صورتحساب",
    addressInfo: "اطلاعات آدرس",
    coinfo: "اطلاعات شرکت",
    acclevel: "سطح حساب",
    uploadpassornatid: "بارگزاری پاسپورت یا کارت شناسایی",
    province: "استان",
    uploadlogo: "لوگو",
    uploadlatestcompanychange: "آگهی آخرین تغییرات شرکت",
    uploadnewspaper: "تصویر روزنامه رسمی",
    uploadnatidsign: "کارت ملی یا شناسنامه صاحبان امضا",
    personal: "شخصی",
    free: "رایگان",
    business: "کسب و کار",
    tobusiness: "به کسب و کار",
    whoYouSend: "به چه کسی پول ارسال میکنید؟",
    monthly: "ماهه",
    annually: "ساله",
    packagedes: "توضیحات پکیج",
    premiumbusiness: "کسب و کار ویژه",
    buy: "خرید",
    sell: "فروش",
    settings: "تنظیمات",
    reports: "رویداد ها",
    toAmount: "تبدیل شده به",
    yourcurrency: "ارز مرجع شما",
    autoexit: "خروج خودکار",
    zone: "منطقه زمانی",
    twostepverify: "ورود دو مرحله ای",
    autorenewalacc: "تمدید خودکار سطح حساب",
    actions: "عملیات",
    device: "دستگاه",
    ip: "آی پی",
    deactiveacc: "غیر فعال کردن حساب",
    currentpass: "کلمه عبور فعلی",
    newpass: "کلمه عبور جدید",
    repeatpass: "تکرار کلمه عبور جدید",
    smsnotify: "اطلاع رسانی از طریق پیامک",
    emailnotify: "اطلاع رسانی از طریق ایمیل",
    account: "حساب کاربری",
    notify: "اطلاع رسانی",
    sendNewTicket: "ارسال تیکت جدید",
    sendTicketResponse: "پاسخ",
    sendTicketEmail: "پاسخ تیکت شما به ایمیل شما ارسال خواهد شد.",
    websitename: "نام وب‌سایت",
    websitetype: "نوع وب‌سایت",
    category: "گروه",
    subCategory: "زیر‌گروه",
    messageContent: "متن پیام",
    uploadbill: "بارگزاری قبض",
    you: "شما",
    supp: "پشتیبانی",
    downloadFile: "دانلود فایل",
    uploadfile: "بارگزاری فایل",
    ticketstitle: "تیکت ها",
    ticketsDescription:
      "تیکت، یکی از راه های تضمین شده و بدون تاخیر مشتریان با دپارتمان های مختلف و یکی از پرکاربرد ترین و ساده ترین راه های ارتباطی با یک پی می باشد. ما را در این راه ارتباطی همراهی نمایید.",
    ticketsList: "لیست تیکت ها",
    title: "عنوان",
    lastUpdate: "آخرین بروزرسانی",

    exchange: "اکسچنج",
    walletpayout: "برداشت از کیف پول",
    customized: "سفارشی",
    lastMonth: "ماه گذشته",
    last6months: "۶ ماه گذشته",
    lastYear: "سال گذشته",
    lastWeek: "هفته قبل",
    websitegatewaytitle: "درخواست درگاه پرداخت وب سایت",
    websitegatewaydesc:
      "اگر جزو افرادی هستید که می‌خواهید کسب‌ و کار خود را خارج از مرزها گسترش دهید، یک پی برای اولین بار با ارائه درگاه پرداخت ارزی به وب سایت های ایرانی و فراهم کردن امکان پرداخت وجه توسط مشتری ها با ارزهای مختلف، فروش یا صادرات خدمات و محصولات به سرتاسر دنیا را تسهیل کرده است.",
    addwebsitegateway: "افزودن درگاه",
    website: "وب‌سایت",
    field: "تخصص",
    gatewayinfo: "اطلاعات درگاه",
    weburl: "آدرس وب‌سایت",
    currentunit: "واحد فعلی",
    programminglanguage: "زبان برنامه نویسی",
    cms: "سیستم مدیریت محتوا",
    staticip: "آی پی ثابت",
    orgemail: "ایمیل سازمانی",
    uploadbranreg: "بارگزاری گواهی ثبت برند",
    successfulydone: "با موفقیت ثبت شد",
    yekpaylinktitle: "یک پی لینک",
    yekpaylinkdesc:
      "یک پی لینک، یکی از ساده ترین و محبوب ترین روش های دریافت وجه می باشد. به محض تکمیل فرم ساخت یک پی لینک، یک لینک اینترنتی و یک QR Code مرتبط با آن به شما اختصاص می یابد. پس از آن می توانید این لینک و یا QR Code را برای مشتریان خود ارسال نمایید تا در کمترین زمان ممکن، وجه ریالی و یا ارزی مورد نظر را در بستر امن بانکی به شما پرداخت نمایند.",
    myyekpaylinks: "یک پی لینک های من",
    addyekpaylink: "اضافه کردن یک پی لینک",
    link: "لینک",
    qrcode: "کد QR",
    validate: "تاریخ اعتبار",
    sendcapability: "قابلیت ارسال",
    selectyouravatar: "تصویر آوتار خود را انتخاب کنید",
    yekstocktitle: "انتقال به بورس",
    accordingToCurrent: "برحسب ارز مبدا",
    accordingToDestination: "برحسب ارز مقصد",
    yekstockdeesc:
      "شما میتوانید موجودی کیف پول خود را به حساب بانکی خود در داخل کشور توسط انتقال پایا و یا از طریق ارسال حواله به حساب های خراج از کشور واریز نمایید. کارمزد خدمات یک پی به شرح زیر است",
    connecttostock: "اتصال به بورس",
    stockcode: "کد بورسی",
    stockname: "نام کارگزار",
    bankaccnum: "شماره حساب",
    expirationdate: "تاریخ انقضا",
    acceptterms: "قوانین و مقررات را میپذیرم",
    send: "ثبت",
    addyourwallet: "کیف پول خود را بسازید",
    addYourBankAccount: "اکانت بانکی خود را بسازید",
    addyourpersonalgateway: "درگاه شخصی خود را بسازید",
    transactionsList: "لیست تراکنش ها",
    websiteGateway: "درگاه پرداخت وبسایت",
    yekpayLink: "یک پی لینک",
    moneyexchange: "انتقال وجه",
    POS: "کارتخوان",
    yekstock: "یک استاک",
    personalGateway: "درگاه پرداخت شخصی",

    sendmoneytitle: "انتقال وجه",
    sendmoneydesc:
      "درگاه پرداخت شخصی، یکی از ساده ترین و محبوب ترین روش های دریافت وجه می باشد. به محض تکمیل فرم ساخت درگاه، یک لینک یکتا و یک QR Code مرتبط با آن به شما اختصاص می یابد. پس از آن می توانید این لینک و یا QR Code را برای مشتریان خود ارسال نمایید تا در کمترین زمان ممکن، وجه ریالی و یا ارزی مورد نظر را در بستر امن بانکی به شما پرداخت نمایند.",
    transfertitle: "پرداخت حواله",
    transferdesc: "شما میتوانید کیف پول خود را شارژ کنید.",
    transfer: "حواله",
    currencyunit: "واحد ارزی",
    details: "توضیحات",
    transactions: "تراکنش ها",
    transactionInfo: "اطلاعات تراکنش",
    withdraw: "برداشت",
    receive: "دریافت",
    referenceno: "شماره مرجع",
    type: "نوع",
    previous: "قبلی",
    next: "بعدی",
    noDataFound: "شما تاکنون تراکنشی انجام نداده اید",
    page: "صفحه",
    event: "رویداد",
    of: "از",
    rows: "سطر",
    pageJump: "پریدن به صفحه",
    rowsSelector: "سطر در صفحه",
    to: "پرداخت به",
    for: "پرداخت برای",
    cancel: "انصراف",
    payer: "پرداخت کننده",
    confirmmobnum: "تایید شماره همراه",
    exchangeRate: "نرخ ارز",
    select2: "انتخاب",
    sendmoney: "ارسال مبلغ به",
    paymentlinkdesc:
      "جهت درخواست درگاه ارزی و یا ریالی برای وب سایت خود، می بایست فرم مورد نظر را تکمیل نمایید. سپس همکاران بخش درگاه",
    paymentlinkruleslink: "لینک",
    pay: "پرداخت",
    changePass: "تغییر رمز عبور",
    done: "با موفقیت انجام شد",
    forgotPassConfirmCodeWrong: "اشتباه است",
    info: "مشخصات",
    verifylogin: "کد تایید",
    persian: "فارسی",
    trackingCode: "کد رهگیری",
    transactionConfirmNote:
      "تراکنش شما به شرح زیر می باشد. لطفا طی زمان اعلامی نسبت به تایید و یا لغو این تراکنش اقدام نمایید.",
    repeatUsername: "نام کاربری قبلا استفاده شده است",
    errorNotFound: "صفحه مورد نظر شما پیدا نشد!",
    approved: "تائید شده",
    Approved: "تائید شده",

    planUpgraded: "طرح شما با موفقیت آپگرید شد",
    failed: "شکست خورد",
    lowBalance: "موجودی حساب شما کم است. لطفا شارژ کنید",
    errorTitle: "مشکلی پیش آمد!",
    errorDescription: "خطای داخلی سرور",
    commission: "مبلغ کمیسیون ",
    deleteAccount: "حذف حساب کاربری",
    deleteText:
      "در صورتی که اکانت کاربری خود را حذف کنید، بر اساس قوانین و مقررات یک پی، اطلاعات مربوط به آن تا 5 سال نزد یک پی باقی خواهد ماند. همچنین پس از حذف اکانت خود، دیگر قادر به بازگرداندن آن نخواهید بود.",
    confirmation: "تایید اطلاعات",
    source: "مبداٰ",
    excel: "خروجی اکسل",
    cancelplan: "خیر",
    okplan: "سطح حساب کاربری شما ارتقا یافت",
    bitcoinWalletPayout: "برداشت از کیف پول بیت کوین",
    bitcoinWalletTopup: " شارژ کیف پول بیت کوین",
    ethereumWalletPayout: "برداشت از کیف پول اتریوم",
    ethereumWalletTopup: " شارژ کیف پول اتریوم ",
    tetherWalletTopup: "شارژ کیف پول تتر",
    bitcoinDes:
      "جهت شارژ کیف پول بیت کوین می بایست تراکنش خود را با آدرس کیف پول مقصد زیر انجام دهید. پس از واریز موفق، کیف پول شما به همان مقدار شارژ خواهد شد.",
    DestinationWallet: "آدرس کیف پول مقصد",
    notification: "اعلان ها",
    notif_num: "شماره",
    seeAll: "مشاهده همه",
    seen: "دیده شده",
    uploaddoc: "بارگزاری مدارک",
    websiteInfo: "اطلاعات وب سایت",
    technicalInfo: "اطلاعات فنی",
    agentInfo: "اطلاعات نماینده",
    unseen: "دیده نشده",
    news: "اخبار",
    exchangeCryptoToCrypto: "  اکسچنج ارز به سایر رمز ارز ها ",
    exchangeCryptoToFiat: "اکسچنج رمز ارز به ارز های فیات",
    DestinationWallet: "آدرس کیف پول مقصد",
    registerRequest: "ثبت درخواست",
    registerDescription:
      "وب سایت شما جهت درخواست درگاه پرداخت ثبت گردید. لطفا مدارک مورد نیاز برای تایید وب سایت را ارسال نمایید.",
    yekpayLinkRegister: "یک پی لینک شما به آدرس زیر ایجاد گردید.",
    seeAllyekpaylink: "مشاهده یک پی لینک ها",
    linkInfo: "اطلاعات لینک",
    cryptoIRRPayout:
      "شما می توانید مقدار بیت کوین مورد نظر خود را به حساب های بانکی داخل ایران واریز نمایید. کارمزد یک پی بابت این سرویس 0.2 درصد می باشد. ",
    ActiveYekpaylink: " فعال سازی یک پی لینک",
    YekpayUserEmail: "ایمیل کاربر یک پی",
    equivalentToRials: "معادل ریالی",
    selectinventory: "(انتخاب کل موجودی)",
    btcToRial: "قیمت هر بیت کوین",
    viewTicket: "مشاهده تیکت",
    cryptoToFiatNote:
      "شما می توانید مقدار بیت کوین مورد نظر خود را به سایر ارزهای فیات اکسچنج نمایید. توجه داشته باشید در صورت اکسچنج بیت کوین به ارزهای فیات، در هنگام برداشت از کیف پول 5 درصد آن به عنوان کارمزد کسر می گردد. در صورت تمایل به برداشت مقدار بیت کوین خود به صورت ریالی می توانید از منوی برداشت، گزینه واریز به حساب ریالی را انتخاب نمایید.",
    feeNote:
      "کارمزد انتقال مربوط به ثبت تراکنش در شبکه‌ی بیت کوین بوده و یک پی در‌ آن ذینفع نیست",
    yekpayLinkStatus:
      "یک پی لینک ساخته شده به جهت محدودیت در تعداد یک پی لینک های فعال در سطح حساب شما غیر فعال می باشد. جهت فعالسازی به صفحه یک پی لینک های من رفته و سایر یک پی لینک ها را غیر فعال و یک پی لینک جدید را فعال نمایید.",
    payoutConfirm: "تایید برداشت",
    crop: "برش",
    browse: "انتخاب",
  },
  en: {
    fullNameAccHolder: "Full name of the account holder",
    account_holder_name: "Account Holder Name",
    sendMoney: "Send Money",
    change: "Change",
    International: "International",
    sameCurrency: "Same Currency",
    sortCode: "Sort code",
    outsideEurope: "Outside Europe",
    insideEurope: "Inside Europe",
    swift_BICcode: "SWIFT / BIC code",
    BAN: "BAN / Account number",
    localBankAccount: "Local Bank Account",
    interac: "Interac",
    interacEmail: "Interac Registered Email",
    reviewDetail: "Review details of your transfer",
    countryCode: "Country Code",
    country_code: "Country Code",
    firstLine: "Address",
    first_line: "Address",
    postCode: "Post Code",
    post_code: "Post Code",
    state: "State",
    transit: "Transit And Account Number",
    institutionNo: "Institution Number",
    BSBCode: "BSB Code",
    BIK: "BIK (Bank Code)",
    bankAccountCountry: "Bank Account Country",
    accountType: "Account Type",
    account_type: "Account Type",
    routing_number: "ACH Routing number",
    accountNo: "Account Number",
    ACHRoutingnumber: "ACH Routing number",
    iban: "IBAN",
    IBAN: "IBAN",
    swift_code: "Swift Code",
    account_number: "Account Number",
    sort_code: "Sort Code",
    theirAccountHolderFirstName: "Firstname of the account holder",
    theirAccountHolderLastName: "Lastname of the account holder",
    NameOfBusiness: "Name of the business",
    theirEmail: "Their Email",
    bank: "Bank",
    holderName: "Account holder firstname",
    holderLastName: "Account holder lastname",
    whoYouSend: "Who are you sending money to?",
    browse: "Browse",
    crop: "Crop",
    changeAddress: "Change Address",
    addressPlaceholder: `Unit,No.,Alley,Street`,
    inPendingStatus: "Pending Approval",
    companyInfo: "Company Informations",
    personalDocs: "Identity Documents",
    personalinfo: "Identity Informations",
    juridical: "juridical",
    corporateBtn: "Add legal user information",
    convertToCorporate:
      "If your account belongs to an organization (legal user), enter the information of the organization you want ",
    withdrawalsource: "Withdrawal amount from the source wallet",
    getAmount: "Amount received in the destination wallet",
    accordingToCurrent: "According to source currency",
    accordingToDestination: "According to destination currency",
    payoutConfirm: "Payout Confirmation",
    payoutReceipt: "Payout Receipt",
    viewTicket: "View Ticket",
    cryptoToFiatNote:
      "You can exchange the amount of bitcoin you want to other Fiat currencies. Note that if Bitcoin is exchanged for Fiat currencies, 5% of it will be deducted as a commission when withdrawing from the wallet. If you want to withdraw your bitcoin amount in Rials, you can select the deposit option in Rials account from the withdrawal menu.",
    turkishID: "Turkish Identity Number",
    transactionConfirmNote:
      "Your transaction is as follows. Please confirm or cancel this transaction at the time of announcement.",
    btcToRial: "Price Per Bitcoin",
    selectinventory: "(Select the entire inventory)",
    equivalentToRials: "Equivalent to Rials",
    YekpayUserEmail: "Yekpay User Email",
    cryptoIRRPayout:
      "You can deposit the amount of bitcoin you want to bank accounts in Iran. The yekpay fee for this service is 0.2%.",
    ethereumWalletPayout: "Ethereum Wallet Payout",
    ActiveYekpaylink: "Active Yekpay Link",
    yekpayLinkStatus:
      "A Yekpay link is disabled due to a limit on the number of active links at your account level. To activate, go to the page of my Yekpay links and deactivate the other links and activate the new Yekpay link.",
    feeNote:
      "The transfer fee is related to the registration of the transaction in the Bitcoin network and yekpay is not a beneficiary",
    payoutAttention:
      "Attention: Entering the wrong address may result in a loss of your financial resources.",
    seeAllyekpaylink: "See All Yekpay Links",
    yekpayLinkRegister: "Yekpay link was created to the following address.",
    linkInfo: "Link Informations",
    registerDescription:
      "Your website has been registered to request a payment gateway. Please submit the documents required to verify the website.",
    websiteInfo: "Website Information",
    technicalInfo: "Technical Information",
    agentInfo: "Agent Information ",
    registerRequest: "Register",
    exchangeCryptoToCrypto: "Exchange Crypto To Crypto",
    exchangeCryptoToFiat: "Exchange Crypto To Fiat",
    toForeignAccount: "To Another Address ",
    toRialAccount: "To Rial Account",
    news: "News",
    websiteInfo: "Website Information",
    technicalInfo: "Technical Information",
    agentInfo: "Agent Information ",
    register: "Regi ster",
    seen: "Seen",
    unseen: "Unseen",
    seeAll: "See All",
    exchangeReceipt: "Exchange Receipt",
    transactionConfirm: "Transaction Confirm",
    transactionReceipt: "Transaction Receipt",
    notif_num: "Number",
    notification: "Notification",
    confirmations: "Confirmations",
    created_at: "Created At",
    fee: "Fee",
    updated_at: "Updated At",
    gatewayImage: "Gateway Image",
    gatewayUsername: "Username",
    toAmount: "Convert into",
    calculate: "Calculate",
    accountVerification: "Account Verification",
    copy: "Copy",
    ethereumWalletTopup: "Ethereum Wallet Topup",
    tetherWalletTopup: "Tether Wallet Topuo",
    DestinationWallet: "Destination Wallet Address",
    bitcoinWalletPayout: "Bitcoin Wallet Payout",
    bitcoinDes:
      "To top up your Bitcoin wallet, you must complete your transaction with the following destination wallet address. Upon a successful deposit, your wallet will be charged the same amount.",
    cryptoAddress: "Receive address",
    cryptoCurrencyWallet: "Crypto Currency Wallet",
    bitcoinWalletTopup: "Bitcoin Wallet Topup",
    fiatWallets: "Fiat Wallets",
    five: "(5%)",
    tfirstamount: "Amount deducted from the wallet",
    tamount: "Transfer amount",
    sendTo: "Send to",
    yourBalance: "Your account balance ",
    okplan: "Your account level has been upgraded. ",
    payoutWireDescript:
      "to withdraw from your wallet and transfer to a bank account abroad, you must complete the withdrawal amount, destination account information, reason for transfer and additional information in the bank transfer form. After registering, your account and destination information will be checked and the requested amount will be transferred within 3 to 10 working days. Withdrawal fee is 5% of the transfer amount and the minimum fee is 50 EUR",
    mySelf: "MySelf",
    paymentDescription:
      "your transfer request has been submited with information below and to approve the opration,please press on confirm",
    paymentConfirmation: "Payment Conformation",
    transactionInfo: "Transaction Information",
    reciption: "Reciption",
    comment: "Remittance description ",
    commission5: "As Commission",
    yourSend: "Withdrawal amount from wallet",
    yourSendCrypto: "Withdrawal amount from wallet",
    reciptionGet: "Recipient gets",
    addressInfo: "Address Informations",
    productName: "Product Name",
    confirmedMoney: "Amount to be withdrawn",
    pendingMoney: "Amount under review",
    border: "border-left-0",
    ticket_number: "Ticket Number",
    closed: "Closed",
    yes: "Yes",
    deleteSure: "Are you sure to delete this item?",
    changeSure: "Are you sure to change this item status?",
    fillBillingForm: "Please fill the form",
    refundReason: "Refund Reason",
    refunded: "Refunded",
    yourActivePlan: "Your Active Plan",
    choose: "Select",
    uploadProfilePicture: " Profile Picture",
    verifyMobile: "Verify Mobile",
    active: "Active",
    inactive: "Inactive",
    Active: "Approved",
    Inactive: "Declined",
    makeActive: "Activate",
    makeInactive: "Deactivate",
    enter5DigitNumber:
      "Enter 5 digit number that has been sent to your mobile number",
    optional: "Optional",
    businessPlus: "Business Plus",
    merchantCode: "Merchant Code",
    trial: "Trial",
    availableAmount: "Available amount",
    payoutAmount: "Payout Amount",
    yourResponse: "Your Answer",
    supportResponse: "Support Answer",
    logout: "Logout",
    pleaseChooseYourUsername:
      "Please enter your personal payment gateway username",
    editGateway: "Edit Gateway",
    pleaseEnterGateWayInformation: "Please enter your website information",
    pleaseEnterGateWayTechnicalInformation:
      "Please enter your website technical information",
    pleaseEnterGateWayContactInformation:
      "Please enter your website contact information",
    individual: "Individual",
    toIndividual: "To Individual",

    corporate: "Corporate",
    uploadLogo: "Upload Logo",
    bankAccountReceiptTooltip:
      "This field is not required for Iranian Rial bank accounts." +
      "<br/>" +
      "Although for international bank accounts there is need to upload a picture of the bank receipt containing the account holder's name and IBAN number.",
    pleaseEnterYekpayLinkInformation:
      "Please enter your Yekpay link information",
    pleaseSelectYekpayLinkImage: "Please select Yekpay link image",
    uploadImage: "Upload Image",
    firstNameCanNotChange:
      "The first name entered can not be changed after registration.",
    lastNameCanNotChange:
      "The last name entered can not be changed after registration.",
    emailCanNotChange:
      "The email entered can not be changed after registration.",
    promotionCodeTooltip: "Enter your promotion code if you have.",
    bankAccountImageValidation: "(Max: 2Mg, jpg,png)",
    Upload: "Upload",
    passportUploadTooltip:
      "Please provide a photo of you, holding your passport alongside your face. Make sure your face be visible and passport details are clearly readable.",
    "passport/identity": "Passport/Identity Card",
    profilePicture: "Profile Picture",
    yekpayLinkDescription: "Services/Goods Description",
    yekpayLinkDescriptionPlaceholder:
      "Please describe in details which services or goods you want to sell.",
    yekpayLinkDPayerescriptionPlaceholder:
      "Please describe in details which services or goods you have purchased from the vendor",
    name: "Name",
    euroLimitation: "Min: 5 EUR - Max : 2500 EUR",
    tryLimitation: "Min: 10 TRY - Max : 15000 TRY",
    gbpLimitation: "Min: 5 GBP - Max : 2000 GBP",
    aedLimitation: "Min: 10 AED - Max : 10000 AED",
    individualGatewayDocText:
      "جهت تایید درگاه لطفا مدارک را بارگذاری نمایید: (تصویر کارت ملی - تصویر شناسنامه - اجاره نامه یا سند مالکیت)",
    CorporateGatewayDocText:
      "جهت تایید درگاه لطفا مدارک را بارگذاری نمایید: (آگهی روزنامه رسمی - آگهی آخرین تغییرات - تصویر کارت ملی صاحبان امضا - تصویر شناسنامه - اجاره نامه یا سند مالکیت)",
    GatewayDocImageValidation: "(Max: 2Mg, jpg,png,pdf)",
    official_newspaper: "Official Newspaper",
    identity_card: "Identity Card",
    addwebsitegatewaydocument: "Website gateway documents",
    upload: "Upload",
    melli_card: "Melli Card",
    shenasname: "Shenasname",
    passport: "Passport",
    ownership_documnet: "Ownership Document",
    bussiness_license: "Business License",
    police_license: "Police License",
    institute_license: "Institute License",
    activity_license: "Activity License",
    ownership_melli_card: "Ownership Melli Card",
    last_changes_certification: "Last Changes Certification",
    websiteGatewayDocForApproval: "Website payment gateway documents",
    upload_guidance: "Upload Guidance",
    selectDocType: "Select Document Type",
    gatewayDoc1:
      "جهت تایید درگاه پرداخت وبسایت می بایست مدارک زیر بارگذاری و توسط واحد بازرسی تایید گردد:",
    gatewayDoc2: "مدارک مورد نیاز اشخاص حقیقی: ",
    gatewayDoc3:
      "تصویر کارت ملی - تصویر شناسنامه - اجاره و یا سند مالکیت همنام با دارنده حساب کاربری",
    gatewayDoc4: "مدارک مورد نیاز اشخاص حقوقی: ",
    gatewayDoc5:
      "آگهی روزنامه رسمی - آگهی آخرین تغییرات شرکت - تصویر کارت ملی صاحبان امضا - تصویر شناسنامه صاحبان امضا - اجاره نامه و یا سند مالکیت به نام شرکت",
    gatewayDoc6: "در نظر داشته باشید:",
    gatewayDoc7: "تمامی مدارک می بایستی با کیفیت بالا و رنگی اسکن شده باشند.",
    gatewayDoc8:
      "حجم هر فایل می بایست کمتر از 2 مگابایت و با یکی از فرمت های jpeg,png,pdf باشد.",
    gatewayDoc9:
      "موسسه های خیریه و مردم نهاد، به غیر از موارد اعلامی می بایست مجوز های مربوط از مراجع ذی ربط را نیز بارگذاری نمایند.",
    gatewayDoc10:
      "در صورتی که اشخاص حقیقی اجاره نامه و یا سند مالکیت به نام خود نداشته باشند، می بایست تفاهم نامه با مالک خود را بارگذاری نمایند.",
    yekpay_link_form_title:
      "Please fill out the form below carefully in English. Otherwise your transaction may be canceled.",
    affiliate: "Affiliate",
    affiliate_description: "",
    your_promotion_code: "Your promotion code:",
    registered_users_with_your_code:
      "Registered users with your promotion code",
    done_transaction: "Done Transaction",
    transaction_map: "Transactions Maps",
    available_bussiness_pro: "This feature is available in business plus plan.",
    this_month_trannsactions: "Location of this month transactions",
    bussiness_plus_available:
      "This feature is only available in business plus account",
    cannot_changed:
      "The information entered after registration will not be changed.",
    agree_terms:
      "By clicking submit you are agreeing to the Terms and Conditions.",
    enter_credit_card_info: "Credit Card Information",
    card_number: "Card Number",
    name_on_card: "Name",
    expire_date: "Expire Date (MM/YY)",
    cvc: "CVC",
    add_wallet_for_personal_gateway:
      "You should add wallet for creating your personal payment gateway.",
    active_user_for_personal_gateway:
      "You should activate your account for creating personal payment gateway.",
    active_user_for_yekpay_link:
      "You should activate your account for creating Yekpay link",
    active_profile: "Profile",
    add_wallet: "Add Wallet",
    add_wallet_for_payment_gateway:
      "You should add wallet for creating your website payment gateway.",
    add_wallet_for_yekpay_link:
      "You should add wallet for creating your Yekpay link.",
    unsuccessful_transactions: "Unsuccessful Transactions",
    error_code: "Error Code",
    melli_code_passport_number: "Identity ID or passport number",
    at_now_just_try: "Currently you can just charge your TRY and EUR wallets.",
    at_now_just_try_payment_link:
      "Currently You can just pay in TRY and EUR currencies.",
    payee: "Payee",
    gatewayModal:
      "Due to the Nowruz holidays in Iran and the conditions that happened by Coronavirus, all transactions that would be taken in place from Saturday, March 14, 2020, could not be able to withdraw in IRR currency till Sunday, April 5, 2020. However you can use all Yekpay services as before during this period including website payment gateway, personal payment gateway, Yekpay link, wallet topup and etc.",
    gatewayModal1: "Dear Yekpay user",
    notice: "Notice",
    confirmation: "Confirmation",
    WaitingResponse: "Wating Response",
    answered: "Answered",
    announcementTitle:
      "We have updated our User agreement and Terms of Service.",
    announcementText:
      "Due to current international online payment rules in Yekpay, every online international transaction that will be being made from 6 April 2020 would be able to withdraw according to the list below:\n" +
      "Website payment gateway (25 days) " +
      "Personal payment gateway (7 days) " +
      "Yekpay link (7 days) " +
      "Top-up wallet (1 day). " +
      "Please note the transactions in IRR currency through the website payment gateway and top-up services will be held for 3 hours and then they will be approved.\n" +
      "Also, the withdraw process for the transactions that had been made before the mentioned date will be same as before.",
    clickHere: "Click here to check them out.",
    announcement: "Announcements",
    userAgreement: "Read more",
    announcementLink: "https://yekpay.com/en/user-agreement",
    announcementBase: "Warning: ",
    announcementOk: "OK",
    copied: "Copied",
    userAgreement2: "Read More",
    paymentServiceProvider: "Payment Service Provider",
    paymentTerms: "Payment Terms",
    exchangeBlockedText:
      "Due to the current exchange rate fluctuations, the currency exchange operation would be available everyday from 12:30 till 16:00 except holidays.",
    IRR: "IRR",
    yekpayUserEmail: "Yekpay User Email",
    fillInEnglish: "Fill in English",
    readMore: "Read More...",
    websiteSubmit: "Submit Website",
    websiteGatewayChecklist1:
      "To submit a Payment gateway request, Please consider the items below:",
    websiteGatewayChecklist2:
      "Your website content should be uploaded completely.",
    websiteGatewayChecklist3:
      "Your website content should match to Yekpay terms and conditions.",
    websiteGatewayChecklist4:
      "The payment and checkout process has been implemented correctly. (according to pages 7,8 of Yekpay Gateway implementing document)",
    transactionError: "Transaction Error",
    informationChanged: "Your information has been updated.",
    PaymentGatewayDocument: "Website Payment Gateway Documentation",
    search: "Search",
    filter: "Filter",
    cancelplan: "Cancel",
    fromDate: "From Date",
    toDate: "To Date",
    successfulRegister: "Your registration was successful.",
    errorCode: "Error Code",
    exrate: "Ex. Rate",
    notifications: "Notifications",
    cryptoCurrency: "Crypto Currency",
    sourceWallet: "Source wallet public key",
    cryptoTopupTitle: "Top-Up - Crypto Currencies",
    profileGeneralDescription:
      "To confirm the account, you must carefully complete the following information. Upon completion, your information will be reviewed by the support unit within 24 hours and the result will be announced.",
    accountPlanDescription:
      "To upgrade your account, you have to top up your Euro wallet, or first charge your IRR wallet and then exchange it to Euro.",
    seePricingPage: "View features of plans",
    pluginDescription:
      "To buy plugins, you have to top up your Euro wallet, or first charge your IRR wallet and then exchange it to Euro.",
    yourEmailVerified: "Your email address verified.",
    activateProfile: "Verify your profile",
    paymentLink:
      "Due to the technical updates and security enhancements, there might be some malfunctions on Personal Payment Gateway and Yekpay links until next announcement. We will inform you via email as soon as the mentioned services are available again.",
    thanks: "We apologize in advance for any inconvenience.",
    exchangeWarning:
      "If you are exchanging your international currency to IRR, please pay attention that withdrawing Rial deposits takes place with delay due to Iranian banking restrictions. Please exchange your deposit if you are sure about this action.",
    dearUser: "Dear Yekpay user,",
    payoutWarning:
      "Due to the Iranian banking restrictions on the transfer of Rial deposits, currently, the possibility of withdrawing money from the IRR wallet will be disrupted. Please send your request through the ticket system. Your bank account information will be included in the payment priority list and the payout will be made in the next upcoming days.",
    pluginShopDescription:
      "To download the plugin, you must add your requested plugins to the cart and pay for it. After successful payment, the download link of the requested plugins will be sent to your email address.",
    addToCart: "Add to Cart",
    cart: "Cart",
    addToCartTitle: "The desired plugin has been added to your cart.",
    irr: "IRR",
    removeCart: "Remove from cart",
    pluginCartDescription:
      "Please check your shopping cart before paying and read the rules and agreementss.",
    productLogo: "Product Logo",
    productName: "Product Name",
    productAmount: "Product Amount",
    totalAmount: "Total Amount",
    rules: "User and Agreements",
    payWith: "Online Pay",
    receipt: "Payment Receipt",
    receiptDesc:
      "Your transaction with tracking number 1234 was completed successfully. The download link was sent to your email address. Your bill is as follows:",
    removeCartTitle:
      "The desired plugin has been removed from your shopping cart.",

    LirpayoutWireDescript:
      "To withdraw from your wallet and transfer to bank accounts in Turkey, you must complete the withdrawal amount, destination account information, the reason for the transfer and additional information on the bank transfer form. After registering, your account and destination information will be checked and the requested amount will be transferred within a maximum of 3 working hours (9 am to 4 pm Turkish time). Withdrawal fee is 5% of the transfer amount and the minimum fee is 30 lira.",

    PayoutRedirect:
      "To withdraw from the wallet, you must have a bank account in lira in your account. ",

    errorlogin:
      "You are logged in to another browser or your session is over. Please log in again.",

    yourRequestRegistered: "Your request has been done successfully.",
    submit: "Submit",

    successful: "Successful",
    unSuccessful: "Unsuccessful",
    ok: "OK",
    fullName: "Full Name",
    description: "Description",

    email: "Email",
    password: "Password",
    login: "Login",
    doNotHaveAccount: "Do not have account?",
    createAccount: "Create Account",
    forgotPasswordQuestion: "Forgot Password?",
    loginDescription:
      "Yekpay is the first international online payment platform, aims to facilitate international transactions for individuals and businesses with safe, fast and fair-cost services on the secure banking platform.",
    TimerDescription: "To resend the code",
    TimerSubmit: "Resend",
    forgotPassword: "Forgot Password",
    forgotPasswordEmailSent:
      "Verification code has been sent to your email address.",
    confirmationCode: "Verification Code",
    confirmationCodeAccepted:
      "Your verification code accepted. Now you can set your new password.",
    passwordChangedSuccessfully: "Your password has been changed successfully",

    register: "Register",
    firstName: "First Name",
    lastName: "Last Name",
    promotionCode: "Promotion Code",
    haveAccount: "Already have account?",
    mobile: "Mobile",
    phone: "Phone",
    googleSignIn: "Sign In With Google",
    googleSignUp: "Sign Up With Google",
    verifyMobile: "Receive Code",
    mobileActivation: "Mobile Activation",
    mobileVerificationCodeSend:
      "Verification code has been sent to your mobile number",
    mobileVerificationDescription:
      "To receive verification code, Please insert your mobile number",
    sendMobileVerificationAgain: "Click here if you did not get message",
    emailActivation: "Email Activation",
    emailVerificationCodeSent:
      "Verification code has been sent to your email address",
    emailVerificationDescription:
      "5 Digit verification code has been sent to your email address, Please paste it to below field",
    sendEmailVerificationAgain: "Click here if you did not get email",
    doneSuccessfully: "Done Successfully",

    purpose: "Purpose",

    topup: "Top-Up",
    chargeWalletTitle: "Top-Up Wallet",
    chargeWalletDescription:
      "You can top up money to your IRR or other wallets either with your SHETAB card or international debit cards instantly or via banking wire transfer (depending on deposit time).",
    selectChargeWalletMethod:
      "You can top up your wallet using international debit cards or international wire transfer.",
    shetabCards: "Shetab Cards(IRR)",
    wire: "Wire Transfer",
    creditCards: "Debit Cards",
    irrTopupDescription:
      "To charge your IRR wallet, you can use your own IRR debit card to charge your wallet between 5000 Tomans and 50 million Tomans. After a successful transaction, the charged amount will be checked within 3 hours and added to your wallet.",
    irrTopupTitle: "Top-Up - Shetab Cards(IRR)",
    wireTopupTitle: "Top-Up Wallet - Wire Transfer",
    wireTopupDescription:
      "You can top up your wallet via banking wire transfer. For this purpose fill the form according payer information. We will check your request until 24 hours and send you our bank account details. Your wallet will be charged after your successful wire transfer",
    creditTopupTitle: "Top-Up Wallet - Debit Cards",
    creditTopupDescriptionEUR:
      " To charge with international cards, by activating the 3D-Secure option (the second dynamic code) and also activating the possibility of payment in the Middle East region on your card, you will be able to charge your wallet. The minimum charge of the wallet is 5 EUR and the maximum is 2500 EUR per transaction. After a successful transaction, the desired amount will be checked within 24 hours and added to your wallet.",
    creditTopupDescriptionGBP:
      " To charge with international cards, by activating the 3D-Secure option (the second dynamic code) and also activating the possibility of payment in the Middle East region on your card, you will be able to charge your wallet. The minimum charge of the wallet is 5 GBP and the maximum is 2000 GBP per transaction. After a successful transaction, the desired amount will be checked within 24 hours and added to your wallet.",
    creditTopupDescriptionTRY:
      " To charge with international cards, by activating the 3D-Secure option (the second dynamic code) and also activating the possibility of a payment in the Middle East region on your card, you will be able to charge your wallet. The minimum charge of the wallet is 10 TRY and the maximum is 10000 TRY per transaction. After a successful transaction, the desired amount will be checked within 24 hours and added to your wallet. 18% Value Added Tax (KDV) will be charged ",
    newWallet: "New Wallet",
    walletDeposit: "wallet-deposit",
    walletPayout: "Payout",
    walletCharge: "Top-Up Wallet",
    walletExchange: "Exchange",
    createWallet: "Add Wallet",

    transactionReceipt: "Transaction Receipt",
    transactionTime: "Transaction Time",
    transactionStatus: "Transaction Status",

    payout: "Payout",
    payoutDescription:
      "You can payout your wallet balance to your Iranian bank accounts via PAYA transfer or by wire transfer to your international bank accounts.",
    selectPayoutMethod: "Choose your method for payout",
    payoutIRRTitle: "Payout - IRR Payout",
    payoutIRRDescription:
      "You can payout your IRR wallet balance to the iranian bank account you have defined before. This is possible on Iranian banks working days (saturday - wednesday) from 8 am to 3:30 pm. Note that the sum of the requested amount and the commission should not exceed your IRR wallet balance.",
    payoutTransferTitle: "Payout - Transfer to another yekpay account",
    payoutTransferDescription:
      "You can transfer wallet balance to another Yekpay account instantly without any commission. Note that your destination Yekpay account must have this wallet.",
    payoutWireTitle: "Payout - Wire Transfer",
    payoutWireDescription:
      "You can payout your wallet balance to your bank accounts with wire transfer. For this purpose, please fill the below form. ",
    IRRPayout: "IRR Payout",

    bankAccount: "My Bank Accounts",

    companyName: "Company Name",

    download: "Download",
    buyPlugin: "Buy Plugin",

    exchangeTitle: "Exchange",
    exchangeDescription:
      "To exchange your fund currency, please select the source wallet, destination, and amount requested for an exchange. Note that any currency exchange includes 500 Rials commission in IRR currency originating and in other currency exchanges 0.30 units of origin currency will be deducted for each exchange operation. Due to the current exchange rate fluctuations, the currency exchange operation would be available every day from 12:30 till 16:00 except holidays.",
    fromWallet: "Source Wallet",
    toWallet: "Destination Wallet",

    yekpayLinkName: "Yekpay Link Name",
    operations: "Operations",

    toAnotherYekpay: "To Another Yekpay Account",
    wireTransfer: "Wire Transfer",

    yekpay: "Yekpay",
    wallet: "Wallet",
    wrongPassword: "نام کاربری و کلمه عبور همخوانی ندارند.",
    reason: "Reason",
    refundTitle: "Refund",
    refund: "Refund",
    emailVerificationHasSent:
      "Verification code has been sent to your email address",
    plsVerifyEMailAgain: "Send verification code again",
    verify: "Verify",
    plsVerifyEMailDesc:
      "5 Digit verification code has been sent to your email address, Please paste it to below field",
    plsVerifyEMail: "Email Address Verification",
    email_has_taken: "This email address has been taken.",
    yekpaydashboard: "Yekpay Dashboard",
    tickets: "Tickets",
    messages: "Messages",
    exit: "Sign Out",
    dollar: "Dollar",
    euro: "Euro",
    copyright: "Copyright \u00A9 Yekpay. All Rights Reserved.",
    chooseimage: "Choose image",
    edit: "Edit",
    remove: "Remove",
    loading: "Loading",
    addbankacc: "Add Bank Account",
    shabanum: "Sheba Number",
    swiftnum: "Swift code",
    swiftplaceh: "Swift code is not necessary for iranian bank accounts.",
    accholdername: "Account holder name",
    nameandfamilyname: "Full Name",
    bankandbranch: "Bank and branch name",
    bankandbranchplaceh: "HSBC",
    receiptstatusupload:
      "Please upload the account statement or bank receipt which includes your first and last name and Sheba / IBAN number  ",
    uploadreceipt: "Upload Receipt",
    add: "Add",
    mybankaccounts: "My Bank Accounts",
    addbankaccounts: "Add Bank Account",
    start: "Start",
    end: "End",
    renew: "Renew",
    bank_code: "Bank Code",
    bankaccounttitle: "My Bank Accounts",
    bankaccountdesc:
      "You need add bank account for payout your wallets balance. We will check your bank account information until 24 hours.",
    bankaccountlist: "Bank Accounts List",
    personname: "Name",
    status: "Status",
    createddate: "Created Date",
    documents: "Documents",
    codesandissues: "Sample Codes",
    developerstitle: "Developer Guides",
    developersdesc:
      "You can buy and download Yekpay payment plugins if you use any CMS or use API documents for integration your website to Yekpay payment gateway",
    plugins: "Plugins",
    codes: "Documents",
    pluginshop: "Yekpay Shop",
    woocommerce: "Woocommerce",
    version: "Version",
    language: "Language",
    currency: "Currecny",
    transit_number: "Transit Number",
    ifsc_code: "IFSC Code",
    exchangetitle: "Exchange",
    exchangedesc:
      "جهت تبادل ارز لطفا کیف پول مبدا، مقصد و مبلغ درخواستی جهت تبادل را انتخاب نمایید. توجه داشته باشید هر گونه تبادل ارز شامل 500 ریال کارمزد در تبادل های با ارز مبدا ریال و 30 سنت در تبادل های با ارز مبدا غیر از ریال می گردد.",
    selectwallet: "Select Wallet",
    confirm: "Confirm",
    tCommisssion: "Transitional Commission",
    billreceipt: "Transaction Receipt",
    continue: "Continue",
    back: "Previous",
    complete: "Complete",
    payoutfrom: "Payout From",
    amount: "Amount",
    value: "Amount",
    converto: "Convert To",
    select: "Select",
    confirmexchange: "Exchange Confirmation",
    date: "Date",
    save: "Save",
    print: "Print",
    share: "Share",
    mastercardtitle: "Debit Cards",
    mastercarddesc:
      "برای تبادل ارز لطفا کیف پول مبدا و مقصد خود را انتخاب بفرمایید توجه داشته باشید هرگونه تبادل ارز شامل مالیات خواهد شد. برای تبادل ارز لطفا کیف پول مبدا و مقصد خود را انتخاب بفرمایید توجه داشته باشید هرگونه تبادل ارز شامل مالیات خواهد شد.",
    mymastercards: "کارت های من",
    addmastercard: "اضافه کردن کارت",
    balance: "Balance",
    hour: "Hour",
    platform: "Platform",
    browser: "Browser",
    mastercardname: "نام کارت",
    mastercardwiztittle:
      "قبل از پرداخت میبایست فرم زیر را بر اساس اطلاعات پرداخت کننده تکمیل کنید",
    birthdate: "تاریخ تولد",
    familyname: "نام خانوادگی",
    country: "Country",
    city: "City",
    address: "Address",
    postalcode: "Postal Code",
    nationality: "Nationality",
    nationalcode: "National ID",
    passnum: "Passport Number",
    telephonenum: "Phone Number",
    mobilenum: "Mobile",
    uploadpass: " Passport",
    uploaddoc: "Upload Document",
    mastercardreceipt: "رسید صدور کارت بین المللی",
    paywith: "Pay With Wallet",
    uploadagain: "Re-Upload",
    locationinfo: "Location Information",
    contactinfo: "Contact Information",
    paycontactinfo: "Payee Information",
    docs: "Documents",
    receipt: "Receipt",
    issueDebitCard: "Issue Debit Card",
    mywallettitle: "Create Wallet",
    mywalletdesc:
      "You can build your own wallet to have transaction depends on your account plan. Note that you can not change or delete wallets that you had any successful or unsuccessful transaction on it.",
    yoanwallet: "CNY Wallet",
    rialwallet: "IRR Wallet",
    eurowallet: "EUR Wallet",
    dollarwallet: "USD Wallet",
    chooseYourWallet: "Choose Your Wallet",
    lirwallet: "TRY Wallet",
    addwallet: "Add New Wallet",
    payouttitle: "Payout",
    rial: "IRR",
    method: "Method",
    bankaccount: "My Bank Account",
    selectchargemethod: "روش خود جهت شارژ را انتخاب نمایید",
    rialPayout: "IRR Payout",
    toswift: "Wire Transfer",
    payoutenteramount: "مبلغ مورد نظر برای برداشت وجه را وارد نمایید",
    payoutselecbankacc: "Choose your bank account",
    personalgatewaytitle: "Personal Payment Gateway",
    personalgatewaydesc:
      "You can get money instantly from your customers just via a QR Code or unique URL and send it to your bank account.",
    accountsection: "Profile",
    addavatar: "Add Picture",
    uploadprofile: "Profile Picture",
    uploadPassport: "Selfie with identity card/passport",
    accountview: "Account View",
    selectusername: "Choose your username",
    username: "Username",
    selectavatar: "Upload your personal payment gateway image.",
    uploadavatar: "Upload Picture",
    profile: "Profile",
    general: "General",
    billinfo: "Biling Information",
    coinfo: "Company Information",
    acclevel: "Account Plan",
    uploadpassornatid: "Upload passport/Identity",
    province: "Province",
    companyname: "Cpmpany name",
    uploadlogo: "Logo",
    uploadlatestcompanychange: "Last Company Changes",
    uploadnewspaper: "Official Newspaper",
    uploadnatidsign: "Owner Passport/Identity",
    uploadrentdoc: "Ownership Document",
    personal: "Personal",
    free: "Free",
    business: "Business",
    tobusiness: "To Business",
    monthly: "Monthly",
    annually: "Annually",
    packagedes: "Package Description",
    premiumbusiness: "Business Plus",
    buy: "Buy",
    sell: "Sell",
    settings: "Settings",
    reports: "Reports",
    yourcurrency: "Reference Currency",
    autoexit: "Auto Sign Out",
    zone: "Time/Zone",
    twostepverify: "Two Step Verification",
    autorenewalacc: "Auto Renew Plan",
    actions: "Actions",
    device: "Device",
    ip: "IP",
    deactiveacc: "Delete Account",
    currentpass: "Current Password",
    newpass: "New Password",
    repeatpass: "Confirm New Password",
    smsnotify: "Notification via SMS",
    emailnotify: "Notification via Email",
    account: "Account",
    notify: "Notifications",
    sendNewTicket: "Send New Ticket",
    sendTicketResponse: "Response",
    sendTicketEmail: "Ticket response will be send to your email address.",
    websitename: "Website Name",
    websitetype: "Website Type",
    category: "Category",
    subCategory: "Sub category",
    messageContent: "Message Content",
    uploadbill: "Upload Bill",
    you: "You",
    supp: "Support",
    downloadFile: "Download File",
    uploadfile: "Upload Attachments",
    ticketstitle: "Tickets",
    ticketsDescription:
      "Ticketing is one of the most guaranteed, useful and easiest ways for you to communicate with all Yekpay departments at the earliest possible time. Please keep in touch with us in this way.",
    ticketsList: "Tickets List",
    title: "Title",
    lastUpdate: "Last Update",

    exchange: "Exchange",
    walletpayout: "Payout",
    customized: "Customized",
    lastMonth: "Last Month",
    yekpaylinktitle: "Yekpay Link",
    last6months: "Last 6 Month",
    lastYear: "Last Year",
    lastWeek: "Last Week",
    websitegatewaytitle: "Request Website Gateway",
    websitegatewaydesc:
      "Yekpay Payment Gateway is the best way if you want expand your business around the world. ",
    addwebsitegateway: "Add Gateway",
    website: "Website",
    field: "Filed",
    gatewayinfo: "Gateway Information",
    weburl: "Website URL",
    currentunit: "Current Currency",
    programminglanguage: "Programming Language",
    cms: "CMS",
    staticip: "Static IP",
    orgemail: "Organization Email",
    uploadbranreg: "Logo Registration",
    successfulydone: "Registered Successfully",
    yekpaylinkdesc:
      "You can get money instantly from your customers just via a QR Code or unique URL and send it to your bank account.",
    myyekpaylinks: "My Yekpay Links",
    addyekpaylink: "Add Yekpay Link",
    link: "Link",
    qrcode: "QR Code",
    validate: "Validate",
    sendcapability: "Delivery",
    selectyouravatar: "Choose Your Avatar",
    yekstocktitle: "YekStock",
    yekstockdeesc:
      "شما میتوانید موجودی کیف پول خود را به حساب بانکی خود در داخل کشور توسط انتقال پایا و یا از طریق ارسال حواله به حساب های خراج از کشور واریز نمایید. کارمزد خدمات یک پی به شرح زیر است",
    connecttostock: "Connect to stock",
    stockcode: "Stock Code",
    stockname: "Brokcer Name",
    bankaccnum: "Account Number",
    expirationdate: "Expire Date",
    acceptterms: "I agree term and conditions.",
    send: "Send",
    addyourwallet: "Create your own wallet",
    addYourBankAccount: "Add your bank account",
    addyourpersonalgateway: "Create your personal payment gateway",
    transactionsList: "Transactions List",
    websiteGateway: "Website Payment Gateway",
    yekpayLink: "Yekpay Link",
    moneyexchange: "Transfer Money",
    POS: "V-POS",
    yekstock: "Yek Stock",
    personalGateway: "Personal Payment Gateway",

    sendmoneytitle: "Transfer Money",
    sendmoneydesc:
      "درگاه پرداخت شخصی، یکی از ساده ترین و محبوب ترین روش های دریافت وجه می باشد. به محض تکمیل فرم ساخت درگاه، یک لینک یکتا و یک QR Code مرتبط با آن به شما اختصاص می یابد. پس از آن می توانید این لینک و یا QR Code را برای مشتریان خود ارسال نمایید تا در کمترین زمان ممکن، وجه ریالی و یا ارزی مورد نظر را در بستر امن بانکی به شما پرداخت نمایند.",
    chargewallettitle: "شارژ کیف پول",
    chargewalletdesc:
      "شما می توانید کیف پول های ریالی و ارزی خود را با کارت شتابی یا کارت های بین المللی به صورت آنی و یا به وسیله حواله بانکی (بسته به زمان واریز) شارژ نمایید.",
    chargewalletamount: "مبلغ مورد نظر برای شارژ کیف پول.",
    transfertitle: "پرداخت حواله",
    transferdesc: "شما میتوانید کیف پول خود را شارژ کنید.",
    shetabcard: "Shetab Card(IRR)",
    transfer: "Wire Transfer",
    othercards: "کارت‌های ارزی",
    currencyunit: "Currency",
    details: "Details",
    transactions: "Transactions",
    withdraw: "Payout",
    receive: "Receive",
    referenceno: "Reference",
    type: "Type",
    previous: "Previous",
    next: "Next",
    noDataFound: "No data found",
    page: "Page",
    event: "Event",
    of: "Of",
    rows: "Rows",
    pageJump: "Go to page",
    rowsSelector: "Rows Per Page",
    confirmcode: "Verification Code",
    to: "Pay To",

    for: "Pay For",
    cancel: "Cancel",
    payer: "Payer",
    confirmmobnum: "Mobile Verification",
    export: "Export",
    exchangeRate: "Exchange Rate",
    select2: "Select",
    sendmoney: "Send Money To",
    paymentlinkdesc:
      "جهت درخواست درگاه ارزی و یا ریالی برای وب سایت خود، می بایست فرم مورد نظر را تکمیل نمایید. سپس همکاران بخش درگاه",
    paymentlinkruleslink: "لینک",
    pay: "Pay",
    changePass: "Change Password",
    done: "Done",
    forgotPassConfirmCode:
      "Verificaiton code has been sent to your email adress.",
    forgotPassConfirmCodeWrong: "Is Not Valid",
    info: "Information",
    verifylogin: "Verification Code",
    persian: "Persian",
    trackingCode: "Tracking code",
    repeatUsername: "Username already taken.",
    errorNotFound: "Page Not Found!",
    approved: "Approved",
    pending: "Pending",
    suspend: "Suspend",
    declined: "Declined",
    Approved: "Approved",
    Pending: "Pending",
    suspend: "Suspend",
    Declined: "Declined",
    planUpgraded: "Your plan upgraded successfully",
    failed: "Failed",
    discount: "Discount Code",
    lowBalance: "Your wallet balance is low.",
    errorTitle: "Oops!",
    errorDescription: "Internal server error ",
    commission: "Commission",
    deleteAccount: "Delete Account",
    deleteText:
      "By deleting your Yekpay account, your account information will remain on Yekpay database for 5 years, according to Yekpay privacy policy. Also, note that deleted accounts can not be restored.",
    canceled: "کنسل شده",
    source: "Source",
    "excel ": "Export EXCEL",
    delete: "Delete",
    toMyself: "To MySelf",
    toOthers: "To Others",
    planAlert: "Do you want to upgrade your account? ",
  },
};

export default languages;
