import { WALLETS } from '../actions';


let initialWallets =[];
const WalletsReducer = (state=initialWallets , action) => {

    switch (action.type) {
        case WALLETS:
            return {...state, ...action.payload};
        default:
            return state
    }
};

export default WalletsReducer;
