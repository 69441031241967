/**
 * Created by morteza on 5/14/17.
 */
import {DATA} from '../actions';

const dataReducer = (state = "en", action) => {
    switch (action.type) {
        case DATA:
            return action.data;
        default:
            return state
    }
};

export default dataReducer;