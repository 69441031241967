let errorsStrings = {
  fa: {
    emailIsNotValid: "آدرس ایمیل معتبر نمی باشد.",
    inputIsNotValid: "ورودی معتبر نمی باشد.",
    inputRequired: "وارد کردن این ورودی الزامی می باشد.",
    fillInEnglish: "لطفا به زبان انگلیسی وارد کنید",
    insertYourNumber: "لطفا شماره همراه خود را وارد نمایید",
    insertNumber: "لطفا شماره همراه خود را به انگلیسی وارد کنید",
    formNotEqualPass: "کلمه عبور و تکرار آن یکسان نیست",
    TRYCurrencyAmount: "مبلغ ورودی می بایست بیشتر ۳۰ لیر باشد.",
    eurCurrencyAmount:
      "مبلغ ورودی می بایست بیشتر از ۵ و کمتر از ۲۵۰۰ یورو باشد.",
    irrCurrencyAmount:
      "مبلغ می بایست بزرگتر از 1000 تومان و کوچکتر از 50 میلون تومان باشد.",
    tokenIsNotValid: "کد تایید معتبر نمی باشد.",
  },
  en: {
    formNotEqualPass: "Password and its repetition are not the same",
    emailIsNotValid: "Email address is not valid.",
    inputIsNotValid: "Value is not valid",
    fillInEnglish: "Please enter in english",
    insertYourNumber: "Please insert your phone number",
    insertNumber: "Please insert mobile number",
    TRYCurrencyAmount: "Amount must be higher than 30.00 TRY",
    eurCurrencyAmount:
      "Amount must be lower than 2500.00 and higher than 5.00 EUR",
    irrCurrencyAmount:
      "Amount must be lower than 500,000,000 IRR and higher than 10,000 IRR.",
    inputRequired: "This field is required.",
    tokenIsNotValid: "Verification code is not valid.",

    required: "is required",
  },
};

export default errorsStrings;
