import { showModal } from "actions";
import languages from "languages";
import { useState } from "react";
import { store } from "reducers/Store";
import { getError } from "Values";

const useApi = (apiFunc, onSuccess, onFail) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState("");
  const lang = localStorage.getItem("lang");

  const fail = (data) => {
    setError(true);
    setLoading(false);
    onFail && onFail();

    if (data) {
      const bootData = store.getState().dataReducer;
      const errorDescription = getError(bootData, data.code, lang);
      let description = errorDescription ?? languages[lang].errorDescription;
      // store.dispatch(
      //   showModal("QuestionDialog", {
      //     type: "danger",
      //     description,
      //   })
      // );
    }
  };

  const success = (data) => {
    setData(data);
    onSuccess && onSuccess(data);
    setLoading(false);

    return data;
  };

  const request = async (...args) => {
    setLoading(true);

    //calling api
    const res = await apiFunc(...args);

    //on Fails
    if (!res.ok) return fail(res?.data);

    //on Success
    return success(res.data.data);
  };

  return { loading, error, data, request };
};
export default useApi;
